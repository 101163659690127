import React, { Fragment } from 'react';
import styles from './topPanel.module.scss';
import { Desktop } from 'components/HOCS/Desktop';
import { Mobile } from 'components/HOCS/Mobile';
import { EventsActions } from '../EventsActions';
import { AddAction } from '../../DownTimes/AddAction';
import { IStoreState } from 'services/store/interfaces';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Filter } from '../../../Filter';
import { calendarEventsExport } from 'services/api/calendarEventsExport';
import { Export } from '../../Reports/Export';

export const TopPanel = () => {
	const { t } = useTranslation();
	const { type, count } = useSelector((state: IStoreState) => state.events);
	const { listEvents } = useSelector((state: IStoreState) => state.filter);
	const isDowntimes = type === 'downTimes';
	const isEvents = type === 'events';

	return (
		<Fragment>
			<Desktop>
				<div className={styles.panel}>
					<div className={classNames(styles.panel_left, styles.panel_actions)}>
						{!isDowntimes && <Filter type={type} />}
					</div>

					<div className={classNames(styles.panel_center, styles.panel_actions)}>
						<EventsActions />
					</div>

					<div className={classNames(styles.panel_right, styles.panel_actions)}>
						{isDowntimes && <AddAction />}
						{isEvents && (
							<Export
								text={t('export.promo')}
								filename={t('export.promo')}
								filters={listEvents}
								onExport={calendarEventsExport}
								disabled={count === 0}
							/>
						)}
					</div>
				</div>
			</Desktop>

			<Mobile>
				<div className={styles.events}>
					<EventsActions />
				</div>
			</Mobile>
		</Fragment>
	);
};
