import { IStoreState, TFilterTypes } from 'services/store/interfaces';
import { useSelector } from 'react-redux';
import { AppDispatch } from 'services/store';
import {
	hideEventsFilter,
	hideGoodsFilter,
	hideMultiOrdersFilter,
	hideOrderFilter,
	hideOrdersFilter,
	hideReportsAllGoodsFilter,
	hideReportsCurrentFilter,
	hideReportsPlanningFilter,
	hideReportsQualityFilter,
	showEventsFilter,
	showGoodsFilter,
	showMultiOrdersFilter,
	showOrderFilter,
	showOrdersFilter,
	showReportsAllGoods,
	showReportsCurrent,
	showReportsPlanning,
	showReportsQuality,
	showMessagesFilter,
	hideMessagesFilter,
} from 'services/store/filter';

export const mapSetTitle = {
	orders: 'filters.orders',
	order: 'filters.order',
	goods: 'filters.goods',
	events: 'filters.events',
	notifications: 'filters.notifications',
	multiOrders: 'filters.multiOrders',
	allGoods: 'filters.allGoods',
	quality: 'filters.quality',
	current: 'filters.current',
	planning: 'filters.filter',
	divide: '',
	downTimes: '',
};

export const mapIsShow = (type: TFilterTypes | 'downTimes') => {
	const {
		isShowOrders,
		isShowOrder,
		isShowEvents,
		isShowMultiOrders,
		isShowGoods,
		isShowReportsQuality,
		isShowReportsCurrent,
		isShowReportsAllGoods,
		isShowReportsPlanning,
		isShowMessages,
	} = useSelector((state: IStoreState) => state.filter);

	return {
		orders: isShowOrders,
		order: isShowOrder,
		goods: isShowGoods,
		events: isShowEvents,
		multiOrders: isShowMultiOrders,
		notifications: isShowMessages,
		divide: isShowMultiOrders,
		allGoods: isShowReportsAllGoods,
		quality: isShowReportsQuality,
		current: isShowReportsCurrent,
		planning: isShowReportsPlanning,
		downTimes: false,
	}[type];
};

export const mapShow = (dispatch: AppDispatch) => ({
	orders: () => dispatch(showOrdersFilter()),
	order: () => dispatch(showOrderFilter()),
	goods: () => dispatch(showGoodsFilter()),
	events: () => dispatch(showEventsFilter()),
	multiOrders: () => dispatch(showMultiOrdersFilter()),
	notifications: () => dispatch(showMessagesFilter()),
	allGoods: () => dispatch(showReportsAllGoods()),
	quality: () => dispatch(showReportsQuality()),
	current: () => dispatch(showReportsCurrent()),
	planning: () => dispatch(showReportsPlanning()),
	divide: () => {},
	downTimes: () => {},
});

export const mapHide = (dispatch: AppDispatch) => ({
	orders: () => dispatch(hideOrdersFilter()),
	order: () => dispatch(hideOrderFilter()),
	goods: () => dispatch(hideGoodsFilter()),
	events: () => dispatch(hideEventsFilter()),
	multiOrders: () => dispatch(hideMultiOrdersFilter()),
	notifications: () => dispatch(hideMessagesFilter()),
	allGoods: () => dispatch(hideReportsAllGoodsFilter()),
	quality: () => dispatch(hideReportsQualityFilter()),
	current: () => dispatch(hideReportsCurrentFilter()),
	planning: () => dispatch(hideReportsPlanningFilter()),
	divide: () => {},
	downTimes: () => {},
});
