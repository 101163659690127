import React, { ReactElement } from 'react';
import styles from './doc.module.scss';

interface IDocProps {
	title: string;
	text?: string;
	icon?: ReactElement;
	link: string;
	mix?: string;
}

export const Doc: React.FC<IDocProps> = ({ title, text, icon, link, mix }) => {
	return (
		<a href={link} rel="noreferrer" target="_blank" className={mix}>
			<div className={styles.doc}>
				<div className={styles.doc__content}>
					<h2 className={styles.doc__title}>{title}</h2>
					<p className={styles.doc__text}>{text}</p>
				</div>

				<div className={styles.doc__image}>{icon}</div>
			</div>
		</a>
	);
};
