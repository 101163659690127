import React, { Fragment, useEffect } from 'react';
import { OrdersTable } from './OrdersTable';
import { Pagination } from 'components/Common/Pagination';
import { fill } from 'services/utils/js/fill';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState } from 'services/store/interfaces';
import { Loading } from 'components/Common/Loading';
import useBreakpoint from 'services/hooks/useBreakpoint';
import { fetchOrderBuyGroupWithMessages } from 'services/store/orders';
import { fetchOrderBuyGroupWithGroup } from 'services/store/grouping';
import { useLanguage } from 'services/contexts/languageContext';
import { MobileGroup } from './MobileGroup';
import styles from './orderGroup.module.scss';

export const OrdersGroup = () => {
	const language = useLanguage();

	const items = useSelector((state: IStoreState) => state.orders.count);
	const { cancelOrder, ordersList } = useSelector((state: IStoreState) => state.orders);
	const limit = useSelector((state: IStoreState) => state.filter.listOrders.Limit);
	const pages = fill(Math.ceil(items / (limit || 30)));

	const isFilter = useSelector((state: IStoreState) => state.filter.isShowOrders);
	const isSettings = useSelector((state: IStoreState) => state.setting.isShowOrders);
	const isLoadingOrders = useSelector((state: IStoreState) => state.orders.isLoading);
	const GroupParamLevel = useSelector(
		(state: IStoreState) => state.grouping.groupingProps,
		(prev, next) => JSON.stringify(prev) === JSON.stringify(next)
	);

	const { isStartGroup } = useSelector((state: IStoreState) => state.grouping);
	const filters = useSelector((state: IStoreState) => state.filter.listOrders);

	const dispatch = useDispatch();
	const breakpoints = useBreakpoint();
	const isMobile = breakpoints.includes('xs');
	const isActions = isFilter || isSettings;
	const isShowOrdersGroups = isMobile ? !isActions : !isMobile;

	useEffect(() => {
		if (GroupParamLevel.length > 0)
			dispatch(
				fetchOrderBuyGroupWithGroup({
					...filters,
					IsGrouping: '1',
					GroupParamLevel: GroupParamLevel.map(({ id }) => id),
					Language: language === 'ru' ? 1 : 2,
				})
			);

		if (GroupParamLevel.length < 1)
			dispatch(
				fetchOrderBuyGroupWithMessages({
					...filters,
					IsGrouping: '0',
					GroupParamLevel: [],
					Language: language === 'ru' ? 1 : 2,
				})
			);
	}, [filters]);

	useEffect(() => {
		if (!(GroupParamLevel.length > 0 && isStartGroup)) return;

		dispatch(
			fetchOrderBuyGroupWithGroup({
				...filters,
				IsGrouping: '1',
				GroupParamLevel: GroupParamLevel.map(({ id }) => id),
				Language: language === 'ru' ? 1 : 2,
			})
		);
	}, [JSON.stringify(GroupParamLevel), isStartGroup]);

	useEffect(() => {
		return () => {};
	}, []);

	return (
		<Fragment>
			<div className={styles.orderGroup}>
				{isLoadingOrders ? (
					<Loading />
				) : (
					<Fragment>
						<MobileGroup />

						{isShowOrdersGroups && <OrdersTable />}
					</Fragment>
				)}
			</div>

			<Pagination
				pages={pages}
				items={items}
				hidden={ordersList.length === 0 || (cancelOrder.length > 0 && cancelOrder.length === ordersList.length)}
			/>
		</Fragment>
	);
};
