import React, { FC, useState, Fragment, useEffect } from 'react';
import styles from './export.module.scss';
import { Modal } from 'components/Mass/Modal';
import { Points } from 'components/Common/Loading/Points';
import { TError, TFilterEvents, TReportFilterPlanning, TReportsFilter } from 'services/store/interfaces';
import { ReactComponent as ExportSVG } from 'assets/images/actions/reports.svg';
import { removeObjKeys } from 'services/utils/helpers/removeObjKeys';
import { exportFile } from 'services/utils/js/exportToCSV';
import { getIsExcel, getJSON } from 'services/utils/helpers/getIsExcel';
import { useTranslation } from 'react-i18next';
import { useLanguage } from 'services/contexts/languageContext';
import classNames from 'classnames';

interface IExportProp {
	filters: TReportsFilter | TReportFilterPlanning | TFilterEvents;
	text: string;
	filename: string;
	onExport: (filters: any) => Promise<any>;
	disabled: boolean;
}

export const Export: FC<IExportProp> = ({ filename, text, disabled, filters, onExport }) => {
	const { t } = useTranslation();
	const language = useLanguage();
	const [modal, setIsModal] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isSuccess, setIsSuccess] = useState(false);
	const onCloseModal = () => setIsModal(false);

	const onClick = () => {
		setIsLoading(true);

		onExport({ ...removeObjKeys(filters, ['PageNumber', 'Limit']), Language: language === 'ru' ? 1 : 2 })
			.then((res) => res.blob())
			.then((blob) => {
				getIsExcel(blob)
					? exportFile(blob, filename)
					: getJSON(blob).then((err: TError) => ({ type: 'error', text: err.ErrorDescription }));
			})
			.then(() => setIsSuccess(true))
			.then(() => setIsLoading(false));
	};

	useEffect(() => {
		if (isSuccess) {
			setTimeout(onCloseModal);
			setTimeout(() => setIsLoading(false));
			setIsSuccess(false);
		}
	}, [isSuccess]);

	return (
		<Fragment>
			<div
				className={classNames(styles.export, { [styles.export__disabled]: disabled })}
				onClick={() => (disabled ? {} : setIsModal(true))}
			>
				<ExportSVG />
				<span>{t('export.Export')}</span>
			</div>

			{modal && (
				<Modal title={t(text)} description={`${t(text)} ${t('export.takeTime')}`} onClose={onCloseModal}>
					<div className={styles.close} onClick={onCloseModal}>
						{t('actions.close')}
					</div>

					<div className={styles.confirm} onClick={onClick}>
						{isLoading ? <Points style={{ margin: 5 }} /> : t('actions.continue')}
					</div>
				</Modal>
			)}
		</Fragment>
	);
};
