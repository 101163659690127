import styles from './tutorial.module.scss';
import React, { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

export interface ITutorial {
	link: string;
	icon: ReactElement;
	title: string;
	description: string;
}
export const Tutorial: FC<ITutorial> = ({ link, icon, title, description }) => {
	const { t } = useTranslation();

	return (
		<div className={styles.wrapper}>
			<div className={styles.tutorial}>
				<a href={link} rel="noreferrer" target="_blank">
					<div className={styles.tutorial__top}>
						<div>{t(title)}</div>
						{icon}
					</div>

					<div className={styles.tutorial__bottom}>{t(description)}</div>
				</a>
			</div>
		</div>
	);
};
