import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState, TFilterEvents } from 'services/store/interfaces';
import classNames from 'classnames';
import styles from './filterNotifications.module.scss';
import { Button } from 'components/Filter/Actions/Button';
import { Actions } from 'components/Filter/Actions';
import { defaultListMessages, hideMessagesFilter } from 'services/store/filter';
import useBreakpoint from 'services/hooks/useBreakpoint';
import { DateFields } from 'components/Common/DateFields';
import { setUpdateSidebarCount } from 'services/store/notifications';
import { useTranslation } from 'react-i18next';
import { getDateEnd, getDateFilter, getDateMountedFilter, getDateStart } from 'services/utils/js/getFilterDate';
import type { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import { removeObjKeys } from 'services/utils/helpers/removeObjKeys';

interface IFilterEventsProps {
	onChange?: (filters: TFilterEvents) => void;
	onSet?: () => void;
	isNotifications?: boolean;
}

const endDatesFilter = dayjs(getDateEnd(6));
export const FilterNotifications = ({ onChange = () => {}, onSet = () => {} }: IFilterEventsProps) => {
	const { t } = useTranslation();
	const filtersMessages = useSelector((state: IStoreState) => state.filter.listMessages);
	const isCleanFilters = useSelector((state: IStoreState) => state.filter.isCleanMessages);

	const [fields, setFilters] = useState({
		...filtersMessages,
		DateBegin: filtersMessages.DateBegin ? getDateMountedFilter(filtersMessages.DateBegin) : undefined,
		DateEnd: filtersMessages.DateEnd ? getDateMountedFilter(filtersMessages.DateEnd) : undefined,
	});
	const [isChangeBegin, setClearBegin] = useState(false);
	const [isChangeEnd, setClearEnd] = useState(false);

	const breakpoints = useBreakpoint();
	const isDesktop = !['', 'xs', 'xxs'].includes(breakpoints);
	const isMobile = breakpoints.includes('xs');

	const dispatch = useDispatch();

	const onFilters = () => {
		let data = { ...fields };

		const DateEnd = getDateFilter(String(fields.DateEnd));
		const DateBegin = getDateFilter(String(fields.DateBegin));

		if (fields.DateEnd) {
			data = { ...data, DateEnd };
		}

		if (fields.DateBegin) {
			data = { ...data, DateBegin };
		}

		onChange({ ...data });
	};

	useEffect(() => {
		if (
			dayjs(fields.DateBegin ? getDateFilter(fields.DateBegin) : undefined) >
			dayjs(fields.DateEnd ? getDateFilter(fields.DateEnd) : undefined)
		) {
			const filters = removeObjKeys(fields, ['DateEnd']);
			setFilters({ ...filters });
		}
	}, [fields.DateBegin]);

	useEffect(() => {
		onFilters();
	}, [JSON.stringify(fields)]);

	useEffect(() => {
		if (isDesktop && isCleanFilters) handlerClear();
	}, [isCleanFilters]);

	const onBegin = (value: unknown, dateString: string) => {
		setFilters({ ...fields, DateBegin: dateString });
		setClearBegin(true);
	};

	const onEnd = (value: unknown, dateString: string) => {
		const isEmptyBegin = fields.DateBegin === '';
		const isEmptyEnd = dateString === '';
		const DateEnd = isEmptyBegin ? '' : isEmptyEnd ? dayjs().toISOString().slice(0, 10) : dateString;

		setFilters({ ...fields, DateEnd });
		setClearEnd(true);
	};

	const handlerClear = () => {
		dispatch(setUpdateSidebarCount(true));

		setFilters(defaultListMessages);
		setClearBegin(false);
		setClearEnd(false);
	};

	const handlerClose = () => dispatch(hideMessagesFilter());
	const disabledDateStart: RangePickerProps['disabledDate'] = (current) => {
		return current.isBefore(dayjs(getDateStart())) || current.isAfter(dayjs(getDateEnd(6)));
	};

	const disabledDateEnd: RangePickerProps['disabledDate'] = (current) => {
		const startDate = dayjs(getDateFilter(fields.DateBegin || ''));

		return fields.DateBegin
			? current.isBefore(startDate) || current.isAfter(endDatesFilter)
			: current.isBefore(dayjs(getDateStart())) || current.isAfter(endDatesFilter);
	};

	return (
		<Fragment>
			<div className={styles.filter}>
				<div className={classNames(styles.item)}>
					<span className={styles.item_label}>{t('events.period')}</span>

					<DateFields
						key="datefields"
						onBegin={onBegin}
						onEnd={onEnd}
						begin={fields.DateBegin ? dayjs(fields.DateBegin, 'DD.MM.YY') : undefined}
						end={fields.DateEnd ? dayjs(fields.DateEnd, 'DD.MM.YY') : undefined}
						isChangeBegin={isChangeBegin}
						isChangeEnd={isChangeEnd}
						disabledDateStart={disabledDateStart}
						disabledDateEnd={disabledDateEnd}
					/>
				</div>
			</div>

			<Actions
				confirmButton={<Button title={`${t('actions.use')} ${isMobile ? t('filters.filter') : ''}`} onClick={onSet} />}
				clearButton={
					<Button title={`${t('actions.clean')} ${isMobile ? t('filters.filter') : ''}`} onClick={handlerClear} />
				}
				closeButton={<Button title={t('actions.close')} onClick={handlerClose} />}
			/>
		</Fragment>
	);
};
