export const setDatesFromToText = (language = 'ru', field = 'item') => {
	const scssClass = `[class*="${field}_line__item"]`;
	const items = Array.from(window.document.querySelectorAll(scssClass));

	if (items.length === 0) return;

	const firsts = items.length === 2 ? [0] : items.length === 4 ? [0, 2] : items.length === 8 ? [4, 6] : [2];
	const lasts = items.length === 2 ? [1] : items.length === 4 ? [1, 3] : items.length === 8 ? [5, 7] : [1];

	firsts.map((first) => {
		(items[first] as HTMLElement).title = language === 'ru' ? 'с' : 'from';
	});
	lasts.map((last) => {
		(items[last] as HTMLElement).title = language === 'ru' ? 'по' : 'to';
	});
};
