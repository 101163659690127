import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getOrderBuy } from '../api/getOrderBuy';
import { groupByIdOrder } from 'services/utils/js/groupByDate';
import { updateOrderBuyMulti } from 'services/api/updateOrderBuyMulti';
import { getOrderBuyGroupCheckx } from '../api/getOrderBuyGroupCheckx';
import { IMultiEditOrdersState, TFilterOrders, TOrderId, TUpdateOrderBuyMultiPayload } from './interfaces';

const initialState: IMultiEditOrdersState = {
	ordersInfo: [],
	goodsInfo: [],
	allData: [],
	activeGoods: [],
	quantityList: [],
	isEnableActions: false,
	message: { text: '', type: '' },
	isLoading: false,
	isLoadingMulti: false,
	isUpdated: false,
	errors: {},
};

export const fetchMultiOrderBuy = createAsyncThunk('multiOrders/fetchMultiOrderBuy', async (data: TFilterOrders) => {
	return await getOrderBuy({ ...data, Limit: 10000 });
});

export const fetchUpdateOrderBuyMulti = createAsyncThunk(
	'multiOrders/fetchUpdateOrderBuyMulti',
	async (data: TUpdateOrderBuyMultiPayload) => {
		return await updateOrderBuyMulti(data);
	}
);

export const fetchISEnableActions = createAsyncThunk('multiOrders/fetchISEnableActions', async (data: TOrderId) => {
	return await getOrderBuyGroupCheckx(data);
});

const multiOrders = createSlice({
	name: 'multiOrders',
	initialState,
	reducers: {
		addGood: (state, action) => {
			if (action.payload) state.activeGoods = [...state.activeGoods, action.payload];

			return state;
		},
		removeGood: (state, action) => {
			state.activeGoods = state.activeGoods.filter((id) => id !== action.payload);

			return state;
		},
		setAllActive: (state, action) => {
			const idsGroup = state.goodsInfo.filter((group) => group[0].OrderBuyGroupId === action.payload);
			state.activeGoods = [...state.activeGoods, ...idsGroup[0].map(({ OrderBuyId }) => String(OrderBuyId))];
		},
		removeAllActive: (state, action) => {
			const filteredGoodsId = state.goodsInfo.filter((group) => group[0].OrderBuyGroupId === action.payload);
			const ids = filteredGoodsId[0].map((good) => good.OrderBuyId);

			state.activeGoods = state.activeGoods.filter((id) => !ids.includes(id));
		},
		setQuantityOrder: (state, action) => {
			const data = action.payload;

			const filterList = state.quantityList.filter((order) => order.OrderBuyId !== data.OrderBuyId);
			state.quantityList = [...filterList, data];
		},
		setEmptyQuantityList: (state) => {
			state.quantityList = [];
		},
		closeModal: (state) => {
			state.message = { ...state.message, text: '' };
			state.isLoadingMulti = false;
		},
		setMessage: (state, action) => {
			state.message = action.payload;
		},
		setErrors: (state, action) => {
			state.errors = { ...state.errors, ...action.payload };
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchMultiOrderBuy.pending, (state) => {
			state.isLoading = true;
			state.ordersInfo = [];
			state.goodsInfo = [];
			state.allData = [];
		});
		builder.addCase(fetchMultiOrderBuy.fulfilled, (state, action) => {
			const data = action.payload;
			const res = groupByIdOrder(data);

			state.ordersInfo = res.map((order) => order[0]);
			state.allData = data;
			state.goodsInfo = res;

			state.isUpdated = false;
			state.isLoading = false;
		});
		builder.addCase(fetchMultiOrderBuy.rejected, (state) => {
			state.isLoading = false;
		});
		builder.addCase(fetchISEnableActions.pending, () => {});
		builder.addCase(fetchISEnableActions.fulfilled, (state, action) => {
			const data = action.payload;

			const checkX = data.filter((order) => order.Checkx === '10');
			state.isEnableActions = checkX.length === data.length;
		});
		builder.addCase(fetchISEnableActions.rejected, () => {});
		builder.addCase(fetchUpdateOrderBuyMulti.pending, (state) => {
			state.isLoadingMulti = true;
		});
		builder.addCase(fetchUpdateOrderBuyMulti.fulfilled, (state, action) => {
			const data = action.payload;
			const info = data[0];

			const isErrorFull = data.length > 0 && info.ErrorDescription;
			const isErrorOrder = data.length > 0 && info.Result && info.Result === '99';
			const isError = isErrorFull || isErrorOrder;
			const isSuccess = !isError;

			if (isError) state.message = { text: info?.ResultDescription || 'multiDivide.error', type: 'error' };

			if (isSuccess) {
				state.message = { text: 'multiDivide.success', type: 'success' };
				state.quantityList = [];
				state.activeGoods = [];
				state.isUpdated = true;
				state.isLoadingMulti = false;
				state.errors = {};
			}
		});
		builder.addCase(fetchUpdateOrderBuyMulti.rejected, (state) => {
			state.message = { text: 'multiDivide.call', type: 'error' };
			state.isLoadingMulti = false;
		});
	},
});

export const {
	addGood,
	removeGood,
	setQuantityOrder,
	closeModal,
	setAllActive,
	removeAllActive,
	setEmptyQuantityList,
	setErrors,
} = multiOrders.actions;
export default multiOrders.reducer;
