import React, { Fragment, useEffect, PropsWithChildren, FC } from 'react';
import styles from './events.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState } from 'services/store/interfaces';
import { fetchGetCalendarEvents } from 'services/store/events';
import useBreakpoint from 'services/hooks/useBreakpoint';
import { Pagination } from 'components/Common/Pagination';
import { fill } from 'services/utils/js/fill';
import { Message } from 'components/Common/Message';
import { FilterList } from 'components/Filter/FilterList';
import { Filter } from 'components/Filter';
import { Loading } from 'components/Common/Loading';
import { EventsAll } from './EventsAll';
import { fetchGetNotifications } from 'services/store/notifications';
import { useTranslation } from 'react-i18next';
import { useLanguage } from 'services/contexts/languageContext';

type TContentProps = {
	isShow: boolean;
	text: string;
	isMessageHide?: boolean;
};

type TEventProps = {
	type: 'events' | 'notifications';
	count: number;
};

const title = {
	events: 'pages.events',
	notifications: 'pages.notifications',
};
const Content: FC<PropsWithChildren<TContentProps>> = ({ isShow, text, isMessageHide = false }) =>
	isShow ? <EventsAll /> : <Message text={text} style={isMessageHide ? { display: 'none' } : {}} />;

export const Events: FC<TEventProps> = ({ type, count }) => {
	const { t } = useTranslation();
	const language = useLanguage();

	const messageEvents = useSelector((state: IStoreState) => state.events.message);
	const eventsCount = useSelector((state: IStoreState) => state.events.count);
	const messageNotifications = useSelector((state: IStoreState) => state.notifications.message);
	const messagesCount = useSelector((state: IStoreState) => state.notifications.countNotifications);

	const limitEvents = useSelector((state: IStoreState) => state.filter.listEvents.Limit);
	const limitNotifications = useSelector((state: IStoreState) => state.filter.listMessages.Limit);

	const isLoadingEvents = useSelector((state: IStoreState) => state.events.isLoading);
	const isLoadingNotifications = useSelector((state: IStoreState) => state.notifications.isLoading);

	const filtersEvents = useSelector((state: IStoreState) => state.filter.listEvents);
	const isFiltersEvents = useSelector((state: IStoreState) => state.filter.isShowEvents);

	const filtersNotifications = useSelector((state: IStoreState) => state.filter.listMessages);
	const isFiltersMessages = useSelector((state: IStoreState) => state.filter.isShowMessages);

	const filters = {
		events: filtersEvents,
		notifications: filtersNotifications,
	};

	const message = {
		events: messageEvents,
		notifications: messageNotifications,
	};

	const isFilters = {
		events: isFiltersEvents,
		notifications: isFiltersMessages,
	};

	const limit = {
		events: limitEvents,
		notifications: limitNotifications,
	};

	const isLoading = {
		events: isLoadingEvents,
		notifications: isLoadingNotifications,
	};

	const pages = fill(Math.ceil(count / (limit[type] || 30)));
	const dispatch = useDispatch();
	const breakpoints = useBreakpoint();
	const isMobile = breakpoints.includes('xs');

	const isShowEvents = isMobile ? !isFilters[type] && count > 0 : count > 0;

	useEffect(() => {
		if (type === 'events') dispatch(fetchGetCalendarEvents(filters[type]));
		if (type === 'notifications')
			dispatch(fetchGetNotifications({ ...filters[type], Language: language === 'ru' ? 1 : 2 }));
	}, [filtersEvents, filtersNotifications, type]);

	return (
		<Fragment>
			{isMobile && (
				<Fragment>
					<div className={styles.active}>
						{`${t(title[type])} (${count})`}

						<Filter type={type} />
					</div>

					{isFilters[type] && <FilterList type={type} />}
				</Fragment>
			)}

			{isLoading[type] ? (
				<Loading />
			) : (
				<Content isShow={isShowEvents} isMessageHide={isMobile ? isFilters[type] : false} text={message[type]} />
			)}

			<Pagination
				pages={pages}
				items={count}
				type={type}
				hidden={
					type === 'events' ? eventsCount < (limitEvents || 0) + 1 : messagesCount < (limitNotifications || 0) + 1
				}
			/>
		</Fragment>
	);
};
