import React from 'react';
import { useSelector } from 'react-redux';
import { IStoreState } from 'services/store/interfaces';
import { NewEvents } from './NewEvents';
import { NotificationList } from './NotificationsList';
import styles from './eventsAll.module.scss';

export const EventsAll = () => {
	const type = useSelector((state: IStoreState) => state.events.type);

	return <div className={styles.list}>{type === 'events' ? <NewEvents /> : <NotificationList />}</div>;
};
