import { useState, useEffect } from 'react';
import { IStoreState, TContentBlock } from '../store/interfaces';
import { useSelector } from 'react-redux';
const getMarginPagination = (length: number, callback: (arg: number) => void) => {
	if (length === 0) {
		callback(0);
		return;
	}

	const footer = document.querySelector('[class*=footer]');
	if (footer) callback(footer.getBoundingClientRect().height);
};
export const usePaginationMargin = (type: TContentBlock) => {
	const [marginBottom, setMarginBottom] = useState(0);
	const { activeOrders } = useSelector((state: IStoreState) => state.orders);
	const { activeGoods } = useSelector((state: IStoreState) => state.goods);

	const items = {
		[`orders` as TContentBlock]: activeOrders,
		[`goods` as TContentBlock]: activeGoods,
		[`events` as TContentBlock]: [],
		[`notifications` as TContentBlock]: [],
		[`order` as TContentBlock]: [],
		[`allGoods` as TContentBlock]: [],
		[`current` as TContentBlock]: [],
		[`quality` as TContentBlock]: [],
		[`planning` as TContentBlock]: [],
	};

	useEffect(() => {
		getMarginPagination(items[type].length, setMarginBottom);
	}, [JSON.stringify(items[type])]);

	return marginBottom;
};
