import React, { useEffect } from 'react';
import { Logo } from 'components/Logo';
import { Menu } from 'components/Menu';
import stl from './index.module.scss';
import { Icons } from './Icons';
import { useIsSticky } from 'services/hooks/useIsSticky';
import { useDispatch } from 'react-redux';
import { setTarget } from 'services/store/outside';
import { AdminSession } from './AdminSession';
import { fetchGetIsAllowedDemand } from 'services/store/reports';
import classNames from 'classnames';

const HeaderWithAuth = () => {
	const [isSticky] = useIsSticky();
	const dispatch = useDispatch();

	useEffect(() => {
		// if (pathname.includes('home')) dispatch(fetchGetSupportRequestList());
		dispatch(fetchGetIsAllowedDemand({ ContractorId: localStorage.userId }));
	}, []);

	useEffect(() => {
		const setClickTarget = (event: UIEvent) => dispatch(setTarget(event.target));
		document.body.addEventListener('click', setClickTarget);

		return () => document.body.removeEventListener('click', setClickTarget);
	});

	return (
		<div
			className={classNames(stl.headerWithAuth, {
				[stl.headerSticky]: isSticky,
			})}
		>
			<div className={stl.logoGroup}>
				<Logo />

				{isSticky && <Menu isScrolled={true} />}

				<Icons />
			</div>

			{!isSticky && <Menu />}

			<AdminSession />
		</div>
	);
};

export default HeaderWithAuth;
