import { useEffect, useState, CSSProperties } from 'react';

const getHeight = () => {
	const actions = document.querySelector('[class*="topPanel"]') as HTMLDivElement;
	const events = document.querySelector('[class*="HeaderWithAuth_logoGroup"]') as HTMLDivElement;
	const datePanel = document.querySelector('[class*="topPanel_panel"]') as HTMLDivElement;
	const days = document.querySelector('[class*="month_month_week"]') as HTMLDivElement;
	const menu = document.querySelector('[class*="Menu_nav"]') as HTMLDivElement;
	const dayName = document.querySelectorAll('[class*="month_day"]');

	if (actions && events && datePanel && days && menu) {
		return {
			height:
				(window.innerHeight -
					(actions.getBoundingClientRect().height +
						events.getBoundingClientRect().height +
						datePanel.getBoundingClientRect().height +
						days.getBoundingClientRect().height +
						menu.getBoundingClientRect().height) -
					20) /
				5,
			width: Array.from(dayName)[0].clientWidth + 1,
		};
	}
};

export const useSizesDayCalendar = () => {
	const [size, setSize] = useState<CSSProperties>();

	useEffect(() => {
		setSize(getHeight());

		window.addEventListener('resize', () => setSize(getHeight()));

		return window.removeEventListener('resize', () => setSize(getHeight()));
	}, []);

	return size;
};
