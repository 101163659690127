const getMonthTranslate = (str: string, lang: string) => {
	switch (str) {
		case '01':
			return lang === 'ru' ? 'января' : 'January';
		case '02':
			return lang === 'ru' ? 'февраля' : 'February';
		case '03':
			return lang === 'ru' ? 'марта' : 'March';
		case '04':
			return lang === 'ru' ? 'апреля' : 'April';
		case '05':
			return lang === 'ru' ? 'мая' : 'May';
		case '06':
			return lang === 'ru' ? 'июня' : 'June';
		case '07':
			return lang === 'ru' ? 'июля' : 'July';
		case '08':
			return lang === 'ru' ? 'августа' : 'August';
		case '09':
			return lang === 'ru' ? 'сентября' : 'September';
		case '10':
			return lang === 'ru' ? 'октября' : 'October';
		case '11':
			return lang === 'ru' ? 'ноября' : 'November';
		case '12':
			return lang === 'ru' ? 'декабря' : 'December';
		default:
			return '';
	}
};

export const getDateEvent = (date: string, lang: string) => {
	const dateArr = date.split('.');

	const [day, month, year] = dateArr;
	const engDay = day.includes('0') ? day.split('0')[1] : day;

	return lang === 'ru'
		? `${day} ${getMonthTranslate(month, lang)} ${year}`
		: `${engDay}th of ${getMonthTranslate(month, lang)}, ${year}`;
};
