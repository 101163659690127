import React from 'react';
import styles from './homeText.module.scss';
import { useTranslation } from 'react-i18next';

const texts = [
	{ id: 1, text: 'home.welcomeText.item1' },
	{ id: 2, text: 'home.welcomeText.item2' },
	{ id: 3, text: 'home.welcomeText.item3' },
	{ id: 4, text: 'home.welcomeText.item4' },
	{ id: 5, text: 'home.welcomeText.item5' },
	{ id: 6, text: 'home.welcomeText.item6' },
	{ id: 7, text: 'home.welcomeText.item7' },
];

export const HomeText = () => {
	const { t } = useTranslation();

	return (
		<div className={styles.homeText}>
			<h1 className={styles.homeText__header}>{t('home.welcome')}</h1>
			<ul className={styles.homeText__text}>
				{texts.map(({ id, text }) => (
					<li className={styles.homeText__text_item} key={id}>
						{t(text)}
					</li>
				))}
			</ul>
		</div>
	);
};
