import React, { Fragment } from 'react';
import styles from './planning.module.scss';
import { IStoreState } from 'services/store/interfaces';
import { fill } from 'services/utils/js/fill';
import { Pagination } from 'components/Common/Pagination';
import { useSelector } from 'react-redux';
import { ActualDate } from './ActualDate';
import { Schedule } from './Schedule';
import { AnimatePresence } from 'framer-motion';
import { Table } from './Table';
import { Oferta } from './Oferta';
import { NoResult } from 'components/Common/NoResult';
import { Loading } from 'components/Common/Loading';

const pages = (countPlanning: number, Limit: number) => fill(Math.ceil(countPlanning / (Limit || 30)));

export const PlanningScreen = () => {
	const { listReportsPlanning } = useSelector((state: IStoreState) => state.filter);
	const { loading, listTablePlanning, countPlanning, isPlanningTable } = useSelector(
		(state: IStoreState) => state.reports
	);

	return (
		<Fragment>
			{loading ? (
				<Loading />
			) : countPlanning === 0 ? (
				<NoResult />
			) : (
				<AnimatePresence>
					<div className={styles.planning}>
						<ActualDate />

						{isPlanningTable ? <Table /> : <Schedule />}

						<Oferta />
					</div>
				</AnimatePresence>
			)}

			{isPlanningTable && (
				<Pagination
					pages={pages(countPlanning, listReportsPlanning.Limit)}
					items={countPlanning}
					type="planning"
					hidden={listTablePlanning.length === 0}
				/>
			)}
		</Fragment>
	);
};
