import React, { memo } from 'react';
import { DatePicker } from 'antd';
import classNames from 'classnames';
import styles from 'components/Content/Events/FilterEvents/filterEvents.module.scss';
import localeRu from 'antd/es/date-picker/locale/ru_RU';
import localeEn from 'antd/es/date-picker/locale/en_US';
import { useLanguage } from 'services/contexts/languageContext';
import { ReactComponent as Icon } from 'assets/images/filter/calendar.svg';
import { Dayjs } from 'dayjs';

interface IDateFieldsProps {
	onBegin: (value: unknown, dateString: string) => void;
	onEnd: (value: unknown, dateString: string) => void;
	begin?: null | Dayjs;
	end?: null | Dayjs;
	isChangeBegin?: boolean;
	isChangeEnd?: boolean;
	disabledDateStart?: (date: Dayjs) => boolean;
	disabledDateEnd?: (date: Dayjs) => boolean;
}
export const DateFields = memo(
	({
		onBegin,
		onEnd,
		begin,
		end,
		isChangeBegin = false,
		isChangeEnd = false,
		disabledDateStart,
		disabledDateEnd,
	}: IDateFieldsProps) => {
		const language = useLanguage();

		return (
			<div className={classNames(styles.item_line)}>
				<DatePicker
					onChange={onBegin}
					value={begin}
					className={classNames(styles.item_line__item, {
						[styles.active]: isChangeBegin && begin,
					})}
					placeholder=""
					locale={language === 'ru' ? localeRu : localeEn}
					allowClear={false}
					showToday={false}
					format={'DD.MM.YY'}
					suffixIcon={<Icon />}
					disabledDate={disabledDateStart}
				/>

				<DatePicker
					onChange={onEnd}
					value={end}
					className={classNames(styles.item_line__item, {
						[styles.active]: isChangeEnd && end,
					})}
					placeholder=""
					locale={language === 'ru' ? localeRu : localeEn}
					allowClear={false}
					showToday={false}
					format={'DD.MM.YY'}
					suffixIcon={<Icon />}
					disabledDate={disabledDateEnd}
				/>
			</div>
		);
	}
);
