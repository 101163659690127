import React, { Fragment } from 'react';
import { IStoreState } from 'services/store/interfaces';
import { fill } from 'services/utils/js/fill';
import { Desktop } from 'components/HOCS/Desktop';
import { InfoTable } from 'components/InfoTable';
import { Pagination } from 'components/Common/Pagination';
import { useSelector, useDispatch } from 'react-redux';
import { addQualityItem, removeQualityItem } from 'services/store/reports';
import styles from 'components/Content/Orders/OrdersGroup/OrdersTable/ordersTable.module.scss';
import { NoResult } from 'components/Common/NoResult';
import { Loading } from 'components/Common/Loading';

export const QualityScreen = () => {
	const dispatch = useDispatch();
	const { listReportsQuality } = useSelector((state: IStoreState) => state.filter);
	const { loading, message, listQuality, countQuality } = useSelector((state: IStoreState) => state.reports);
	const pages = fill(Math.ceil(countQuality / (listReportsQuality.Limit || 30)));

	const handleItem = (isActive: boolean) => {
		return (id: string) => (isActive ? dispatch(addQualityItem({ id })) : dispatch(removeQualityItem({ id })));
	};

	return (
		<Fragment>
			{loading ? (
				<Loading />
			) : message !== '' ? (
				<div className={styles.message}>{message}</div>
			) : countQuality === 0 ? (
				<NoResult />
			) : (
				<Desktop>
					<div className={styles.scrollBar}>
						<InfoTable type="quality" onChange={handleItem} />
					</div>
				</Desktop>
			)}

			<Pagination pages={pages} items={countQuality} type="quality" hidden={listQuality.length === 0} />
		</Fragment>
	);
};
