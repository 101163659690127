import React, { FC, PropsWithChildren } from 'react';
import styles from './filterButton.module.scss';
import { DropButton } from 'components/Common/DropButton';
import { ReactComponent as MobileSVG } from 'assets/images/filter/filterMobile.svg';

interface IFilterButtonProps {
	isMobile?: boolean;
	title?: string;
	onClick?: () => void;
}

export const FilterButton: FC<PropsWithChildren<IFilterButtonProps>> = ({ isMobile, title, onClick = () => {} }) => {
	const icon = isMobile ? <MobileSVG /> : null;

	return <DropButton icon={icon} mix={styles.button} text={title} onClick={onClick} />;
};
