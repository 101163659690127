import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState, TFilterEvents } from 'services/store/interfaces';
import styles from './filterEvents.module.scss';
import { Button } from 'components/Filter/Actions/Button';
import { Actions } from 'components/Filter/Actions';
import { defaultListEvents, hideEventsFilter } from 'services/store/filter';
import useBreakpoint from 'services/hooks/useBreakpoint';
import { DateFields } from 'components/Common/DateFields';
import { useTranslation } from 'react-i18next';
import { getDateEnd, getDateFilter, getDateMountedFilter, getDateStart } from 'services/utils/js/getFilterDate';
import { EventsGoods } from '../EventsGoods';
import { TSelectField } from 'components/Common/Input/InputSelect';
import type { RangePickerProps } from 'antd/es/date-picker';
import { removeObjKeys } from 'services/utils/helpers/removeObjKeys';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { setGoodsInEventFilter, setEmptyGoodsInEventFilter } from 'services/store/events';

interface IFilterEventsProps {
	onChange?: (filters: TFilterEvents) => void;
	onSet?: () => void;
	isNotifications?: boolean;
}

const endDatesFilter = dayjs(getDateEnd(6));
export const FilterEvents = ({ onChange = () => {}, onSet = () => {} }: IFilterEventsProps) => {
	const { t } = useTranslation();
	const filtersEvents = useSelector((state: IStoreState) => state.filter.listEvents);
	const isCleanFilters = useSelector((state: IStoreState) => state.filter.isCleanEvents);
	const isShowFilter = useSelector((state: IStoreState) => state.filter.isShowEvents);
	const breakpoints = useBreakpoint();

	const isDesktop = !['', 'xs', 'xxs'].includes(breakpoints);
	const isMobile = breakpoints.includes('xs');

	const [fields, setFilters] = useState<TFilterEvents>(
		isCleanFilters
			? defaultListEvents
			: {
					...filtersEvents,
					DateBegin: filtersEvents.DateBegin ? getDateMountedFilter(filtersEvents.DateBegin) : undefined,
					DateEnd: filtersEvents.DateEnd ? getDateMountedFilter(filtersEvents.DateEnd) : undefined,
					DistributionStartDateBegin: filtersEvents.DistributionStartDateBegin
						? getDateMountedFilter(filtersEvents.DistributionStartDateBegin)
						: undefined,
					DistributionStartDateEnd: filtersEvents.DistributionStartDateEnd
						? getDateMountedFilter(filtersEvents.DistributionStartDateEnd)
						: undefined,
			  }
	);

	const [isChangeBegin, setClearBegin] = useState(false);
	const [isChangeEnd, setClearEnd] = useState(false);
	const [isChangeBeginDistribution, setClearBeginDistribution] = useState(false);
	const [isChangeEndDistribution, setClearEndDistribution] = useState(false);

	const dispatch = useDispatch();
	const onBeginDistribution = (value: unknown, dateString: string) => {
		setFilters({ ...fields, DistributionStartDateBegin: dateString });
		setClearBeginDistribution(true);
	};

	const changeGoods = (values: TSelectField[]) => {
		dispatch(setGoodsInEventFilter(values));
		const Goods = values.length > 0 ? values.map(({ id }) => id || '') : [];
		setFilters({ ...fields, Goods });
	};

	const onEnd = (value: unknown, dateString: string) => {
		const isEmptyBegin = fields.DateBegin === '';
		const isEmptyEnd = dateString === '';
		const DateEnd = isEmptyBegin ? '' : isEmptyEnd ? dayjs().toISOString().slice(0, 10) : dateString;

		setFilters({ ...fields, DateEnd });
		setClearEnd(true);
	};

	const onBegin = (value: unknown, dateString: string) => {
		setFilters({ ...fields, DateBegin: dateString });
		setClearBegin(true);
	};

	const onEndDistribution = (value: unknown, dateString: string) => {
		const isEmptyBegin = fields.DistributionStartDateBegin === '';
		const isEmptyEnd = dateString === '';
		const DistributionStartDateEnd = isEmptyBegin ? '' : isEmptyEnd ? dayjs().toISOString().slice(0, 10) : dateString;

		setFilters({ ...fields, DistributionStartDateEnd });
		setClearEndDistribution(true);
	};

	const handlerClear = () => {
		setFilters(defaultListEvents);
		dispatch(setEmptyGoodsInEventFilter());
		setClearBegin(false);
		setClearEnd(false);
		setClearBeginDistribution(false);
		setClearEndDistribution(false);
	};

	useEffect(() => {
		if (!fields.DateBegin) return;

		if (
			dayjs(fields.DateBegin ? getDateFilter(fields.DateBegin) : undefined) >
			dayjs(fields.DateEnd ? getDateFilter(fields.DateEnd) : undefined)
		) {
			const filters = removeObjKeys(fields, ['DateEnd']);
			setFilters({ ...filters });
		}
	}, [fields.DateBegin, fields.DateEnd]);

	useEffect(() => {
		if (!fields.DistributionStartDateBegin) return;

		if (
			dayjs(fields.DistributionStartDateBegin ? getDateFilter(fields.DistributionStartDateBegin) : undefined) >
			dayjs(fields.DistributionStartDateEnd ? getDateFilter(fields.DistributionStartDateEnd) : undefined)
		) {
			const filters = removeObjKeys(fields, ['DistributionStartDateEnd']);
			setFilters({ ...filters });
		}
	}, [fields.DistributionStartDateBegin, fields.DistributionStartDateEnd]);

	const onFilters = () => {
		let data = { ...fields };

		const DateEnd = getDateFilter(String(fields.DateEnd));
		const DateBegin = getDateFilter(String(fields.DateBegin));
		const DistributionStartDateEnd = getDateFilter(String(fields.DistributionStartDateEnd));
		const DistributionStartDateBegin = getDateFilter(String(fields.DistributionStartDateBegin));

		if (fields.DateEnd) {
			data = { ...data, DateEnd };
		}

		if (fields.DateBegin) {
			data = { ...data, DateBegin };
		}

		if (fields.DistributionStartDateBegin) {
			data = { ...data, DistributionStartDateBegin };
		}

		if (fields.DistributionStartDateEnd) {
			data = { ...data, DistributionStartDateEnd };
		}

		onChange({ ...data, Goods: fields.Goods });
	};

	useEffect(() => {
		onFilters();
	}, [JSON.stringify(fields)]);

	useEffect(() => {
		if (isDesktop && isCleanFilters) handlerClear();
	}, [isShowFilter, isCleanFilters]);

	const disabledDateStart: RangePickerProps['disabledDate'] = (current) => {
		return current.isBefore(dayjs(getDateStart())) || current.isAfter(dayjs(getDateEnd(6)));
	};

	const disabledDateEnd: RangePickerProps['disabledDate'] = (current) => {
		const startDate = dayjs(getDateFilter(fields.DateBegin || ''));

		return fields.DateBegin
			? current.isBefore(startDate) || current.isAfter(endDatesFilter)
			: current.isBefore(dayjs(getDateStart())) || current.isAfter(endDatesFilter);
	};

	const disabledDateEndDistribution: RangePickerProps['disabledDate'] = (current) => {
		const startDate = dayjs(getDateFilter(fields.DistributionStartDateBegin || ''));
		return fields.DistributionStartDateBegin
			? current.isBefore(startDate) || current.isAfter(endDatesFilter)
			: current.isBefore(dayjs(getDateStart())) || current.isAfter(endDatesFilter);
	};

	return (
		<Fragment>
			<div className={styles.filter}>
				<div className={classNames(styles.item)}>
					<span className={styles.item_label}>{t('events.dateNets')}</span>

					<DateFields
						key="Distributionfields"
						onBegin={onBeginDistribution}
						onEnd={onEndDistribution}
						begin={fields.DistributionStartDateBegin ? dayjs(fields.DistributionStartDateBegin, 'DD.MM.YY') : undefined}
						end={fields.DistributionStartDateEnd ? dayjs(fields.DistributionStartDateEnd, 'DD.MM.YY') : undefined}
						isChangeBegin={isChangeBeginDistribution}
						isChangeEnd={isChangeEndDistribution}
						disabledDateStart={disabledDateStart}
						disabledDateEnd={disabledDateEndDistribution}
					/>
				</div>

				<div className={classNames(styles.item)}>
					<span className={styles.item_label}>{t('events.datePromo')}</span>

					<DateFields
						key="PromoDates"
						onBegin={onBegin}
						onEnd={onEnd}
						begin={fields.DateBegin ? dayjs(fields.DateBegin, 'DD.MM.YY') : undefined}
						end={fields.DateEnd ? dayjs(fields.DateEnd, 'DD.MM.YY') : undefined}
						isChangeBegin={isChangeBegin}
						isChangeEnd={isChangeEnd}
						disabledDateStart={disabledDateStart}
						disabledDateEnd={disabledDateEnd}
					/>
				</div>

				<div className={classNames(styles.item)}>
					<span className={styles.item_label}>{t('events.goods')}</span>

					<EventsGoods onChange={changeGoods} />
				</div>
			</div>

			<Actions
				confirmButton={<Button title={`${t('actions.use')} ${isMobile ? t('filters.filter') : ''}`} onClick={onSet} />}
				clearButton={
					<Button title={`${t('actions.clean')} ${isMobile ? t('filters.filter') : ''}`} onClick={handlerClear} />
				}
				closeButton={<Button title={t('actions.close')} onClick={() => dispatch(hideEventsFilter())} />}
			/>
		</Fragment>
	);
};
