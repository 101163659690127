import React, { Fragment, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styles from './userMenu.module.scss';
import { Menu } from 'components/Menu';
import { Callto } from '../Callto';
import { Logout } from './Logout';
import { Personal } from './Personal';
import { fetchLogOut } from 'services/store/jwt';
import { ITutorial, Tutorial } from '../Tutorial';
import { Links } from 'components/Personal';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LightSVG } from 'assets/images/sidebar/light.svg';
import { ReactComponent as VideoSVG } from 'assets/images/sidebar/video.svg';
import { APILink } from './API';

const TUTORIALS: ITutorial[] = [
	{
		link: 'https://drive.google.com/file/d/1r8y6FP5J3Uo7C0GC_igyCnJOgEfsajf_/view?usp=sharing',
		title: 'rightMenu.video',
		description: 'rightMenu.howUse',
		icon: <VideoSVG />,
	},
	{
		link: `/PDFUser.pdf?=${new Date().getTime()}`,
		title: 'rightMenu.howUse',
		description: 'rightMenu.pdf',
		icon: <LightSVG />,
	},
];

export const UserMenu = () => {
	// const language = useLanguage();
	const { t } = useTranslation();
	const dispatch = useDispatch();

	//const [messageSupport, setMessageSupport] = useState({ type: '', message: '' });
	// const isAdmin = useSelector((state: IStoreState) => state.support.isAdmin);
	// const [isAdminSession] = useIsAdminSession();
	//
	// const isSuccessSupport = messageSupport.type === 'success';
	// const isWarningSupport = messageSupport.type === 'warning';

	const logout = useCallback(() => {
		dispatch(fetchLogOut({ IsDelete: '1', ContractorId: localStorage.userId, IsLogOut: 1 }));
	}, []);

	// const onSupport = useCallback(() => {
	// 	sendSupportRequest(language === 'ru' ? 1 : 2).then((res) => {
	// 		if (res[0].Result) setMessageSupport({ type: 'success', message: res[0].Result });
	// 		if (res[0].ErrorDescription) setMessageSupport({ type: 'warning', message: res[0].ErrorDescription });
	// 	});
	// }, [language]);

	return (
		<Fragment>
			<div className={styles.userMenu}>
				<div className={styles.header}>
					<div className={styles.title}>
						{t('rightMenu.hello')}, {localStorage.userName}!
					</div>

					<div className={styles.id}>id: {localStorage.userId}</div>
				</div>

				<div className={styles.userMenu_content}>
					<Menu type="submenu" />

					<div className={styles.list}>
						<Personal link="/profile" title={t('rightMenu.personal')} />
						{/*{!isAdmin && !isAdminSession && <Support title={t('rightMenu.support')} onClick={onSupport} />}*/}

						<APILink text={t('rightMenu.api')} />
						<Logout title={t('rightMenu.logout')} onClick={logout} />
					</div>

					<div className={styles.phone}>
						{/*<p*/}
						{/*	className={classNames(styles.list__support, {*/}
						{/*		[styles.list__support_success]: isSuccessSupport,*/}
						{/*		[styles.list__support_warning]: isWarningSupport,*/}
						{/*	})}*/}
						{/*>*/}
						{/*	{messageSupport.message}*/}
						{/*</p>*/}

						<p className={styles.phone_text}>{t('rightMenu.email')}</p>
						<Callto type="email" email="zakaz_support@eurotorg.by">
							zakaz_support@eurotorg.by
						</Callto>
						<p className={styles.phone_time}>{t('rightMenu.hours')} 9:00-18:00</p>
					</div>

					{TUTORIALS.map((tutorial, inx) => (
						<Tutorial key={inx} {...tutorial} />
					))}

					<Links mix={styles.submenu__cookies} />
				</div>
			</div>
		</Fragment>
	);
};
