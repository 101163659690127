import React, { Fragment } from 'react';
import { DropButton } from 'components/Common/DropButton';
import { ReactComponent as BellSVG } from 'assets/images/events/bell.svg';
import { Clock } from 'assets/images/downTimes/Clock';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState } from 'services/store/interfaces';
import styles from './eventsActions.module.scss';
import { setType } from 'services/store/events';
import { defaultListEvents, defaultListMessages, setFiltersEvents, setFiltersMessages } from 'services/store/filter';
import { CalendarSVG } from 'assets/images/events';
import { Desktop } from 'components/HOCS/Desktop';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

export const EventsActions = () => {
	const { t } = useTranslation();
	const mode = useSelector((state: IStoreState) => state.events.type);

	const isShowEvents = mode === 'events';
	const isShowNotifications = mode === 'notifications';
	const isShowDownTimes = mode === 'downTimes';

	const dispatch = useDispatch();

	return (
		<Fragment>
			<DropButton
				text={t('events.calendar')}
				icon={<CalendarSVG color={isShowEvents ? '#fff' : '#606060'} />}
				onClick={() => {
					dispatch(setFiltersMessages({ filters: defaultListMessages }));
					dispatch(setType('events'));
				}}
				mix={classNames(styles.event, {
					[styles.active]: isShowEvents,
				})}
			/>

			<DropButton
				text={t('events.notifications')}
				icon={<BellSVG />}
				onClick={() => {
					dispatch(setType('notifications'));
					dispatch(setFiltersEvents({ filters: defaultListEvents }));
				}}
				mix={classNames(styles.event, {
					[styles.active]: isShowNotifications,
				})}
			/>

			<Desktop>
				<DropButton
					text={t('events.downtimes')}
					icon={<Clock active={isShowDownTimes} />}
					onClick={() => {
						dispatch(setType('downTimes'));
					}}
					mix={classNames(styles.event, styles.event_downtimes, {
						[styles.event_downtimes__active]: isShowDownTimes,
					})}
				/>
			</Desktop>
		</Fragment>
	);
};
