import React, { useEffect } from 'react';
import styles from './goods.module.scss';
import { Actions } from 'components/Actions';
import { GoodsTable } from './GoodsTable';
import { Pagination } from 'components/Common/Pagination';
import { fill } from 'services/utils/js/fill';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState } from 'services/store/interfaces';
import { fetchGetContractorGoods } from 'services/store/goods';
import { ScrollBar } from 'components/Common/ScrollBar';
import { Loading } from 'components/Common/Loading';
import { useTranslation } from 'react-i18next';

export const Goods = () => {
	const { t } = useTranslation();
	const { count, isLoading, goodsList } = useSelector((state: IStoreState) => state.goods);
	const filters = useSelector((state: IStoreState) => state.filter.listGoods);

	const limit = useSelector((state: IStoreState) => state.filter.listGoods.Limit);
	const pages = fill(Math.ceil(count / (limit || 30)));
	const dispatcher = useDispatch();

	useEffect(() => {
		const fetchData = async () => {
			await dispatcher(fetchGetContractorGoods(filters));
		};

		fetchData().then(() => {});
	}, [filters]);

	return (
		<div className={styles.goods}>
			<h1 className={styles.header}>{t('pages.goods')}</h1>

			<Actions itemsCount={count} type="goods" />

			{isLoading ? (
				<Loading />
			) : (
				<ScrollBar>
					<GoodsTable />
				</ScrollBar>
			)}

			<Pagination pages={pages} items={count} type="goods" hidden={goodsList.length === 0} />
		</div>
	);
};
