import { createSlice } from '@reduxjs/toolkit';
import { IFilterAction, IFilterMessagesAction, IStateFilter } from './interfaces';
import { showGoods, showOrder, showOrders } from './setting';
import { setCurrent } from './orders';
import { getDateEnd, getDateStart } from '../utils/js/getFilterDate';

const Limit = window.innerWidth < 600 ? 1000 : 30;

export const defaultListReportsPlanning = {
	ContractorId: localStorage.getItem('userId') || undefined,
	GoodsId: [],
	PageNumber: 1,
	Limit,
};

export const defaultListOrders = {
	OrderBuyGroupId: [],
	RegionId: [],
	DistrictId: [],
	CityId: [],
	DateBegin: getDateStart(),
	DateEnd: getDateEnd(),
	Checkx: [],
	WarehouseId: [],
	GoodsId: [],
	PageNumber: 1,
	Limit: 30,
};

export const defaultListReportsAllGoods = {
	DateBegin: '1900-01-01',
	DateEnd: '2099-01-01',
	WarehouseId: [],
	RegionId: [],
	PageNumber: 1,
	ReportNumber: '1',
	Limit,
};

export const defaultListReportsCurrent = {
	...defaultListReportsAllGoods,
	ReportNumber: '3',
};

export const defaultListReportsQuality = {
	...defaultListReportsAllGoods,
	ReportNumber: '2',
};

export const defaultListGoods = {
	GoodsId: [],
	GoodsName: '',
	PageNumber: 1,
	Limit,
};

export const defaultListOrderGoods = {
	GoodsId: [],
	GoodsName: '',
	PageNumber: 1,
	Limit: 1000,
};

export const defaultListEvents = {
	DateBegin: undefined,
	DateEnd: undefined,
	DistributionStartDateEnd: undefined,
	DistributionStartDateBegin: undefined,
	Goods: [],
	PageNumber: 1,
	Limit: 20,
};

export const defaultListMessages = {
	DateBegin: undefined,
	DateEnd: undefined,
	PageNumber: 1,
	Limit: 100,
};
const initialState: IStateFilter = {
	isShowOrders: false,
	isShowMessages: false,
	isShowOrder: false,
	isShowGoods: false,
	isShowEvents: false,
	isShowMultiOrders: false,
	isCleanOrders: false,
	isCleanOrder: false,
	isCleanGoods: false,
	isCleanEvents: false,
	isCleanMessages: false,
	isShowReportsAllGoods: false,
	isShowReportsCurrent: false,
	isShowReportsQuality: false,
	isShowReportsPlanning: false,
	isCleanReportsAllGoods: false,
	isCleanReportsCurrent: false,
	isCleanReportsQuality: false,
	isCleanReportsPlanning: false,
	listReportsAllGoods: { ...defaultListReportsAllGoods },
	listReportsCurrent: {
		...defaultListReportsCurrent,
	},
	listReportsQuality: {
		...defaultListReportsQuality,
	},
	listReportsPlanning: {
		...defaultListReportsPlanning,
	},
	listGoods: {
		...defaultListGoods,
	},
	listOrders: {
		...defaultListOrders,
	},
	listOrderGoods: {
		...defaultListOrderGoods,
	},
	listEvents: {
		...defaultListEvents,
	},
	listMessages: {
		...defaultListMessages,
	},
	listMultiOrders: {
		...defaultListOrderGoods,
	},
};

const filter = createSlice({
	name: 'filter',
	initialState,
	reducers: {
		clearReportsAllGoods: (state) => {
			state.isCleanReportsAllGoods = true;
		},
		clearReportsCurrent: (state) => {
			state.isCleanReportsCurrent = true;
		},
		clearReportsQuality: (state) => {
			state.isCleanReportsQuality = true;
		},
		clearReportsPlanning: (state) => {
			state.isCleanReportsPlanning = true;
		},
		clearOrder: (state) => {
			state.isCleanOrder = !state.isCleanOrder;
		},
		clearOrders: (state) => {
			state.isCleanOrders = true;
		},
		clearGoods: (state) => {
			state.isCleanGoods = !state.isCleanGoods;
		},
		clearEvents: (state) => {
			state.isCleanEvents = !state.isCleanEvents;
		},
		clearMessages: (state) => {
			state.isCleanMessages = !state.isCleanMessages;
		},
		cancelClearReportsAllGoods: (state) => {
			state.isCleanReportsAllGoods = false;
		},
		cancelClearReportsCurrent: (state) => {
			state.isCleanReportsCurrent = false;
		},
		cancelClearReportsQuality: (state) => {
			state.isCleanReportsQuality = false;
		},
		cancelClearReportsPlanning: (state) => {
			state.isCleanReportsPlanning = false;
		},
		cancelClearOrder: (state) => {
			state.isCleanOrder = false;
		},
		cancelClearOrders: (state) => {
			state.isCleanOrders = false;
		},
		cancelClearGoods: (state) => {
			state.isCleanGoods = false;
		},
		cancelClearEvents: (state) => {
			state.isCleanEvents = false;
		},
		cancelClearMessages: (state) => {
			state.isCleanMessages = false;
		},
		showReportsAllGoods: (state) => {
			state.isShowReportsAllGoods = !state.isShowReportsAllGoods;
		},
		showReportsCurrent: (state) => {
			state.isShowReportsCurrent = !state.isShowReportsCurrent;
		},
		showReportsQuality: (state) => {
			state.isShowReportsQuality = !state.isShowReportsQuality;
		},
		showReportsPlanning: (state) => {
			state.isShowReportsPlanning = !state.isShowReportsPlanning;
		},
		showOrdersFilter: (state: IStateFilter) => {
			state.isShowOrders = !state.isShowOrders;
		},
		showMultiOrdersFilter: (state: IStateFilter) => {
			state.isShowMultiOrders = !state.isShowMultiOrders;
		},
		showOrderFilter: (state: IStateFilter) => {
			state.isShowOrder = !state.isShowOrder;
		},
		showGoodsFilter: (state: IStateFilter) => {
			state.isShowGoods = !state.isShowGoods;
		},
		showEventsFilter: (state: IStateFilter) => {
			state.isShowEvents = !state.isShowEvents;
		},
		showMessagesFilter: (state: IStateFilter) => {
			state.isShowMessages = !state.isShowMessages;
		},
		hideReportsAllGoodsFilter: (state) => {
			state.isShowReportsAllGoods = false;
		},
		hideReportsCurrentFilter: (state) => {
			state.isShowReportsCurrent = false;
		},
		hideReportsQualityFilter: (state) => {
			state.isShowReportsQuality = false;
		},
		hideReportsPlanningFilter: (state) => {
			state.isShowReportsPlanning = false;
		},
		hideOrdersFilter: (state: IStateFilter) => {
			state.isShowOrders = false;
		},
		hideMultiOrdersFilter: (state: IStateFilter) => {
			state.isShowMultiOrders = false;
		},
		hideOrderFilter: (state: IStateFilter) => {
			state.isShowOrder = false;
		},
		hideGoodsFilter: (state: IStateFilter) => {
			state.isShowGoods = false;
		},
		hideEventsFilter: (state: IStateFilter) => {
			state.isShowEvents = false;
		},
		hideMessagesFilter: (state: IStateFilter) => {
			state.isShowMessages = false;
		},
		setFilterOrderGoods: (state: IStateFilter) => {
			state.listOrderGoods = { ...defaultListOrderGoods };
		},
		setFiltersReportsAllGoods: (state, action) => {
			state.listReportsAllGoods = { ...state.listReportsAllGoods, ...action.payload.filters };
		},
		setFiltersReportsCurrent: (state, action) => {
			state.listReportsCurrent = { ...state.listReportsCurrent, ...action.payload.filters };
		},
		setFiltersReportsQuality: (state, action) => {
			state.listReportsQuality = { ...state.listReportsQuality, ...action.payload.filters };
		},
		setFiltersReportsPlanning: (state, action) => {
			state.listReportsPlanning = { ...action.payload.filters };
		},
		setFiltersReportsPlanningPagination: (state, action) => {
			state.listReportsPlanning = { ...state.listReportsPlanning, ...action.payload.filters };
		},
		setFilterMultiOrders: (state: IStateFilter, action) => {
			state.listMultiOrders = { ...state.listMultiOrders, ...action.payload.filters };
		},
		setFiltersOrders: (state: IStateFilter, action: IFilterAction) => {
			state.listOrders = { ...state.listOrders, ...action.payload.filters };
		},
		setFiltersGoods: (state: IStateFilter, action: IFilterAction) => {
			state.listGoods = { ...state.listGoods, ...action.payload.filters };
		},
		setFiltersEvents: (state: IStateFilter, action: IFilterAction) => {
			state.listEvents = {
				PageNumber: state.listEvents.PageNumber,
				Limit: state.listEvents.Limit,
				...action.payload.filters,
			};
		},
		setFiltersMessages: (state: IStateFilter, action: IFilterMessagesAction) => {
			state.listMessages = {
				PageNumber: state.listMessages.PageNumber,
				Limit: state.listMessages.Limit,
				...action.payload.filters,
			};
		},
		setFiltersOrderGoods: (state: IStateFilter, action: IFilterAction) => {
			state.listOrderGoods = { ...state.listOrderGoods, ...action.payload.filters };
		},
	},
	extraReducers: (builder) => {
		builder.addCase(setCurrent, (state) => {
			state.listOrderGoods = defaultListOrderGoods;
			state.listMultiOrders = defaultListOrderGoods;
		});
		builder.addCase(showOrders, (state: IStateFilter) => {
			state.isShowOrders = false;
		});
		builder.addCase(showGoods, (state: IStateFilter) => {
			state.isShowGoods = false;
		});
		builder.addCase(showOrder, (state: IStateFilter) => {
			state.isShowOrder = false;
		});
	},
});

export const {
	hideMessagesFilter,
	showReportsQuality,
	showReportsCurrent,
	showReportsAllGoods,
	showReportsPlanning,
	showOrdersFilter,
	showOrderFilter,
	setFiltersGoods,
	setFiltersReportsAllGoods,
	setFiltersReportsCurrent,
	setFiltersReportsQuality,
	setFiltersReportsPlanning,
	setFiltersReportsPlanningPagination,
	setFiltersEvents,
	showGoodsFilter,
	hideOrdersFilter,
	hideGoodsFilter,
	hideReportsCurrentFilter,
	hideReportsQualityFilter,
	hideReportsAllGoodsFilter,
	hideReportsPlanningFilter,
	setFiltersOrders,
	setFiltersOrderGoods,
	hideOrderFilter,
	setFilterOrderGoods,
	showEventsFilter,
	hideEventsFilter,
	hideMultiOrdersFilter,
	showMultiOrdersFilter,
	setFilterMultiOrders,
	setFiltersMessages,
	clearGoods,
	clearOrders,
	clearReportsCurrent,
	clearReportsQuality,
	clearReportsAllGoods,
	clearReportsPlanning,
	cancelClearOrders,
	clearOrder,
	clearEvents,
	clearMessages,
	cancelClearReportsAllGoods,
	cancelClearReportsCurrent,
	cancelClearReportsQuality,
	cancelClearReportsPlanning,
	cancelClearEvents,
	cancelClearMessages,
	showMessagesFilter,
} = filter.actions;

export default filter.reducer;
