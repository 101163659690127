import React, { useEffect } from 'react';
import styles from './downTimes.module.scss';
import { DatePanel } from './DatePanel';
import { MonthPanel } from './DatePanel/Month';
import { CurrentPanel } from './CurrentPanel';
import { CalendarPanel } from './CalendarPanel';
import { IStoreState } from 'services/store/interfaces';
import { useSelector, useDispatch } from 'react-redux';
import {
	closeCreateModal,
	closeInfoModal,
	closeUpdateModal,
	fetchGetDowntimesWithAnimate,
} from 'services/store/downTimes';
import { ModePanel } from './ModePanel';
import { ModalDownTimes } from './Modal';
import { InfoModal } from './Modal/InfoModal';
import { setType } from 'services/store/events';
import { setDatesFromToText } from 'services/utils/helpers/setDatesFromToText';
import { useLanguage } from 'services/contexts/languageContext';

export const DownTimes = () => {
	const dispatch = useDispatch();
	const language = useLanguage();
	const { year, month, isOpenCreate, isOpenUpdate, isOpenInfo, mode, currentGroup, monthData } = useSelector(
		(state: IStoreState) => state.downTimes
	);

	useEffect(() => {
		dispatch(fetchGetDowntimesWithAnimate(String(year), String(month.id), mode));
	}, [mode, month.id, year]);

	useEffect(() => {
		if (window.innerWidth > 600) return;

		dispatch(setType('events'));
	}, [window.innerWidth]);

	const closeCreateDialog = () => dispatch(closeCreateModal());
	const closeUpdateDialog = () => dispatch(closeUpdateModal());

	useEffect(() => {
		setTimeout(() => {
			setDatesFromToText(language, 'field');
		});
	}, [isOpenCreate, isOpenUpdate]);

	return (
		<div className={styles.downTimes}>
			<DatePanel>
				<MonthPanel />
				<CurrentPanel />
				<ModePanel />
			</DatePanel>

			{mode === 'year' && <CalendarPanel.YearCalendar />}
			{mode === 'month' && <CalendarPanel.MonthCalendar year={year} month={month} data={monthData} />}

			{(isOpenCreate || isOpenUpdate) && (
				<ModalDownTimes
					ActionType={isOpenUpdate ? '2' : '1'}
					onClose={isOpenUpdate ? closeUpdateDialog : closeCreateDialog}
					{...currentGroup}
				/>
			)}

			{isOpenInfo && <InfoModal onClose={() => dispatch(closeInfoModal())} {...currentGroup} />}
		</div>
	);
};
