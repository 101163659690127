import React, { Fragment } from 'react';
import { IStoreState } from 'services/store/interfaces';
import { fill } from 'services/utils/js/fill';
import { Desktop } from 'components/HOCS/Desktop';
import { InfoTable } from 'components/InfoTable';
import { Pagination } from 'components/Common/Pagination';
import { useSelector, useDispatch } from 'react-redux';
import { addCurrentItem, removeCurrentItem } from 'services/store/reports';
import styles from 'components/Content/Orders/OrdersGroup/OrdersTable/ordersTable.module.scss';
import { NoResult } from 'components/Common/NoResult';
import { Loading } from 'components/Common/Loading';

export const CurrentScreen = () => {
	const dispatch = useDispatch();
	const { listReportsCurrent } = useSelector((state: IStoreState) => state.filter);
	const { loading, message, listCurrent, countCurrent } = useSelector((state: IStoreState) => state.reports);
	const pages = fill(Math.ceil(countCurrent / (listReportsCurrent.Limit || 30)));

	const handleGood = (isActive: boolean) => {
		return (id: string) => (isActive ? dispatch(addCurrentItem({ id })) : dispatch(removeCurrentItem({ id })));
	};

	return (
		<Fragment>
			{loading ? (
				<Loading />
			) : message !== '' ? (
				<div className={styles.message}>{message}</div>
			) : countCurrent === 0 ? (
				<NoResult />
			) : (
				<Desktop>
					<div className={styles.scrollBar}>
						<InfoTable type="current" onChange={handleGood} />
					</div>
				</Desktop>
			)}

			<Pagination pages={pages} items={countCurrent} type="current" hidden={listCurrent.length === 0} />
		</Fragment>
	);
};
