import React, { FC, useEffect, useState } from 'react';
import { InputSelect, TSelectField } from 'components/Common/Input/InputSelect';
import { getContractorGoods } from 'services/api/getContractorGoods';
import { IStoreState } from 'services/store/interfaces';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

interface IEventsGoodsProps {
	onChange: (value: TSelectField[]) => void;
}
export const EventsGoods: FC<IEventsGoodsProps> = ({ onChange }) => {
	const { t } = useTranslation();
	const [goods, setGoods] = useState<TSelectField[]>([]);
	const { eventsGoodsInFilter } = useSelector((state: IStoreState) => state.events);

	useEffect(() => {
		getContractorGoods({ GoodsId: [], GoodsName: '' }).then((data) => {
			const result = data.map(({ GoodsName, GoodsId }) => ({
				id: GoodsId,
				text: GoodsName,
			}));
			setGoods(result as TSelectField[]);
		});
	}, []);

	return (
		<InputSelect
			options={goods}
			onChange={onChange}
			withSearch={true}
			values={eventsGoodsInFilter}
			initial={{ id: '0', text: t('filters.orderComp.productsSelect') }}
		/>
	);
};
