import React from 'react';
import styles from './homeDocs.module.scss';
import { Doc } from './Doc';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PdfSVG } from 'assets/images/home/pdf.svg';
import { ReactComponent as VideoSVG } from 'assets/images/home/video.svg';

const docs = [
	{
		id: '1',
		title: 'home.use',
		text: 'home.videoRec',
		img: <VideoSVG />,
		link: 'https://drive.google.com/file/d/1r8y6FP5J3Uo7C0GC_igyCnJOgEfsajf_/view?usp=sharing',
	},
	{
		id: '2',
		title: 'home.use',
		text: 'home.pdfRec',
		img: <PdfSVG />,
		link: `/PDFUser.pdf?=${new Date().getTime()}`,
	},
];

export const HomeDocs = () => {
	const { t } = useTranslation();

	return (
		<>
			{docs.map(({ id, title, text, img, link }) => (
				<Doc key={id} title={t(title)} text={t(text)} icon={img} link={link} mix={styles.doc} />
			))}
		</>
	);
};
