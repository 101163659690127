import React, { Fragment, useState, useMemo, memo } from 'react';
import styles from './filterList.module.scss';
import { FilterOrders } from 'components/Content/Orders/FilterOrders';
import { FilterGoods } from 'components/Content/Goods/FilterGoods';
import classNames from 'classnames';
import {
	IStoreState,
	TFilterEvents,
	TFilterGoods,
	TFilterMessages,
	TFilterOrders,
	TFilterTypes,
} from 'services/store/interfaces';
import { useDispatch, useSelector } from 'react-redux';
import {
	hideEventsFilter,
	hideGoodsFilter,
	hideMultiOrdersFilter,
	hideOrderFilter,
	hideOrdersFilter,
	setFilterMultiOrders,
	cancelClearOrders,
	setFiltersEvents,
	setFiltersGoods,
	setFiltersOrderGoods,
	setFiltersOrders,
	hideReportsAllGoodsFilter,
	hideReportsQualityFilter,
	hideReportsCurrentFilter,
	setFiltersReportsAllGoods,
	setFiltersReportsQuality,
	setFiltersReportsCurrent,
	cancelClearReportsAllGoods,
	cancelClearReportsQuality,
	cancelClearReportsCurrent,
	setFiltersReportsPlanning,
	hideReportsPlanningFilter,
	cancelClearReportsPlanning,
	setFiltersMessages,
	hideMessagesFilter,
	cancelClearEvents,
	cancelClearMessages,
} from 'services/store/filter';
import { FilterOrder } from 'components/Content/Orders/OrderGroup/FilterOrder';
import { FilterEvents } from 'components/Content/Events/FilterEvents';
import { setEmptyGoods } from 'services/store/goods';
import { setEmptyOrders } from 'services/store/orders';
import { setUpdateSidebarCount } from 'services/store/notifications';
import { CurrentFilter, FilterAllGoods, QualityFilter } from 'components/Content/Reports/Filters';
import { useTranslation } from 'react-i18next';
import { mapSetTitle } from '../map';
import { Planning } from 'components/Content/Reports/Filters/Planning';
import { FilterNotifications } from 'components/Content/Events/FilterNotifications';

interface IFilterList {
	type: TFilterTypes;
	isVisible?: boolean;
}

type TFuncSetField = (T: TFilterOrders | TFilterGoods | TFilterEvents | TFilterMessages) => void;
type TSetFuncsObj = {
	[key in TFilterTypes]: () => void;
};

const FilterComp = (type: TFilterTypes, onChange: TFuncSetField, onSet: TSetFuncsObj) =>
	({
		orders: <FilterOrders onChange={onChange} onSet={onSet[type]} />,
		order: <FilterOrder onChange={onChange} onSet={onSet[type]} />,
		goods: <FilterGoods onChange={onChange} onSet={onSet[type]} />,
		events: <FilterEvents onChange={onChange} onSet={onSet[type]} />,
		notifications: <FilterNotifications onChange={onChange} onSet={onSet[type]} />,
		multiOrders: <FilterOrder onChange={onChange} onSet={onSet[type]} />,
		allGoods: <FilterAllGoods onChange={onChange} onSet={onSet[type]} />,
		current: <CurrentFilter onChange={onChange} onSet={onSet[type]} />,
		quality: <QualityFilter onChange={onChange} onSet={onSet[type]} />,
		planning: <Planning onChange={onChange} onSet={onSet[type]} />,
		divide: <Fragment />,
	}[type]);

export const FilterList = ({ type, isVisible }: IFilterList) => {
	const { t } = useTranslation();
	const isOrders = type === 'orders';
	const dispatch = useDispatch();

	const {
		listOrders,
		listReportsAllGoods,
		listReportsQuality,
		listReportsCurrent,
		listEvents,
		listGoods,
		listMessages,
		listOrderGoods,
		listMultiOrders,
		listReportsPlanning,
	} = useSelector((state: IStoreState) => state.filter);

	const mapFilters = useMemo(
		() => ({
			orders: listOrders,
			goods: listGoods,
			events: listEvents,
			notifications: listMessages,
			order: listOrderGoods,
			multiOrders: listMultiOrders,
			divide: listOrderGoods,
			allGoods: listReportsAllGoods,
			quality: listReportsQuality,
			current: listReportsCurrent,
			planning: listReportsPlanning,
		}),
		[
			listOrders,
			listGoods,
			listEvents,
			listMessages,
			listOrderGoods,
			listReportsQuality,
			listReportsCurrent,
			listReportsQuality,
			listReportsPlanning,
		]
	);

	const [filters, setFilters] = useState({ ...mapFilters[type] });

	const setFields: TFuncSetField = (fields) => setFilters({ ...fields });

	const mapSetFilters: TSetFuncsObj = {
		orders: () => {
			dispatch(setEmptyOrders());
			dispatch(setFiltersOrders({ filters }));
			dispatch(hideOrdersFilter());
			dispatch(cancelClearOrders());
		},
		order: () => {
			dispatch(setFiltersOrderGoods({ filters }));
			dispatch(hideOrderFilter());
		},
		goods: () => {
			dispatch(setEmptyGoods());
			dispatch(setFiltersGoods({ filters }));
			dispatch(hideGoodsFilter());
		},
		events: () => {
			dispatch(setUpdateSidebarCount(false));
			dispatch(setFiltersEvents({ filters }));
			dispatch(hideEventsFilter());
			dispatch(cancelClearEvents());
		},
		notifications: () => {
			dispatch(setUpdateSidebarCount(false));
			dispatch(setFiltersMessages({ filters: filters as TFilterMessages }));
			dispatch(hideMessagesFilter());
			dispatch(cancelClearMessages());
		},
		multiOrders: () => {
			dispatch(setFilterMultiOrders({ filters }));
			dispatch(hideMultiOrdersFilter());
		},
		allGoods: () => {
			dispatch(setFiltersReportsAllGoods({ filters }));
			dispatch(hideReportsAllGoodsFilter());
			dispatch(cancelClearReportsAllGoods());
		},
		quality: () => {
			dispatch(setFiltersReportsQuality({ filters }));
			dispatch(hideReportsQualityFilter());
			dispatch(cancelClearReportsQuality());
		},
		current: () => {
			dispatch(setFiltersReportsCurrent({ filters }));
			dispatch(hideReportsCurrentFilter());
			dispatch(cancelClearReportsCurrent());
		},
		planning: () => {
			dispatch(setFiltersReportsPlanning({ filters }));
			dispatch(hideReportsPlanningFilter());
			dispatch(cancelClearReportsPlanning());
		},
		divide: () => {},
	};

	return (
		<div
			className={classNames(styles.filterList, {
				[styles.filterList_orders]: isOrders,
				[styles.filterList_orders__hidden]: !isVisible,
			})}
		>
			<div className={styles.header}>{t(mapSetTitle[type])}</div>
			<div className={styles.body}>{FilterComp(type, setFields, mapSetFilters)}</div>
		</div>
	);
};

export default memo(FilterList);
