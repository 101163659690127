import { TFilterEvents } from 'services/store/interfaces';
import { requestFile } from 'services/utils/requestFile';

export const calendarEventsExport = async (payload: TFilterEvents): Promise<any[]> => {
	return await requestFile.post(
		'SupplierPortal.CalendarEventsExport',
		{
			...payload,
		},
		localStorage.token
	);
};
