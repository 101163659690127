import React from 'react';
import styles from './policy.module.scss';
import { useTranslation } from 'react-i18next';

export const Policy = () => {
	const { t } = useTranslation();

	return (
		<div className={styles.policy}>
			<div className={styles.policy_header}>{t('rightMenu.howPersonal')}</div>
			<div className={styles.policy_content}>
				<div className={styles.policy_center}>
					<b>1. ОБЩИЕ ПОЛОЖЕНИЯ</b>
				</div>
				<p>
					1.1. Настоящая Политика в отношении обработки персональных данных ООО «Евроторг» (далее – Политика)
					разработана в соответствии с Законом Республики Беларусь от 07.05.2021 № 99-З «О защите персональных данных»
					(далее – Закон) и разъясняет субъектам персональных данных каким образом, для каких целей и на каком правовом
					основании их персональные данные собираются, используются или иным образом обрабатываются, а также отражает
					имеющиеся в связи с этим у субъектов персональных данных права и механизм их реализации.
				</p>
				<p>
					1.2. Контактные данные ООО «Евроторг» (далее – Оператор): - наименование: общество с ограниченной
					ответственностью «Евроторг», - УНП 101168731, адрес места нахождения: Республика Беларусь, 220099, г. Минск, -
					ул. Казинца, д. 52а, ком. 22, почтовый адрес: Республика Беларусь, 220019, г. Минск, ул. Монтажников, д. 2,
					адрес в сети Интернет: https://ir.eurotorg.by/ru, https://evroopt.by, адрес электронной почты (e-mail):
					info@eurotorg.by, obraschenie_01@eurotorg.by, телефон: +375 44 7888880, +375 17 2890000, факс: +375 17
					2798000.
				</p>
				<p>
					1.3. Политика не применяется к обработке персональных данных: работников в процессе трудовой деятельности и
					при осуществлении административных процедур (в отношении работников и бывших работников), пользователей
					интернет-сайтов Оператора (в части cookie-файлов), при осуществлении видеонаблюдения, а также в отношении
					совместной обработки сооператорами – ЗАО «Интернет-магазин Евроопт» и ООО «Евроторг» персональных данных,
					которые получены посредством следующих интернет-сайтов: https://yamigom-store.by, https://et.yamigom-store.by
					и мобильного приложения «ЯМИГОМ – доставка продуктов».
				</p>
				<p>1.4. Политика действует в отношении персональных данных, обрабатываемых Оператором, которые получены:</p>
				<p>
					- посредством следующих интернет-сайтов:{' '}
					<a href="https://invest.evroopt.by" target="_blank" rel="noreferrer">
						https://invest.evroopt.by
					</a>
					,{' '}
					<a href="https://e-account.by" target="_blank" rel="noreferrer">
						https://e-account.by
					</a>
					,{' '}
					<a href="https://evroopt.by" target="_blank" rel="noreferrer">
						https://evroopt.by
					</a>
					,{' '}
					<a href="https://eplus.by" target="_blank" rel="noreferrer">
						https://eplus.by
					</a>
					,{' '}
					<a href="https://igra.evroopt.by" target="_blank" rel="noreferrer">
						https://igra.evroopt.by
					</a>
					,{' '}
					<a href="https://fermer.evroopt.by" target="_blank" rel="noreferrer">
						https://fermer.evroopt.by
					</a>
					,{' '}
					<a href="https://hitdiscount.by" target="_blank" rel="noreferrer">
						https://hitdiscount.by
					</a>
					,{' '}
					<a href="https://groshyk.by" target="_blank" rel="noreferrer">
						https://groshyk.by
					</a>
					,{' '}
					<a href="https://ecity.evroopt.by" target="_blank" rel="noreferrer">
						https://ecity.evroopt.by
					</a>
					,{' '}
					<a href="https://ir.eurotorg.by/ru" target="_blank" rel="noreferrer">
						https://ir.eurotorg.by/ru
					</a>
					,{' '}
					<a href="https://sertifikat.evroopt.by" target="_blank" rel="noreferrer">
						https://sertifikat.evroopt.by
					</a>
					,{' '}
					<a href="https://igra.groshyk.by" target="_blank" rel="noreferrer">
						https://igra.groshyk.by
					</a>
					,{' '}
					<a href="https://e-rabota.by" target="_blank" rel="noreferrer">
						https://e-rabota.by
					</a>
					,{' '}
					<a href="https://bonsticks.evroopt.by" target="_blank" rel="noreferrer">
						https://bonsticks.evroopt.by
					</a>{' '}
					(включая личный кабинет и формы обратной связи на сайтах);
				</p>
				<p>
					- посредством мобильных приложений «Еплюс» и «Мой Грошык» (далее – мобильное приложение), размещенных в сети
					Интернет по следующим адресам: мобильное приложение «Еплюс»:
				</p>
				<li className={styles.policy_list}>• App Store – https://apps.apple.com/by/app/еплюс/id1535603669;</li>
				<li className={styles.policy_list}>
					• Google Play – https://play.google.com/store/apps/details?id=by.besmart.euroopt;
				</li>
				<li className={styles.policy_list}>
					• AppGallery – https://appgallery.huawei.com/app/C104839767. мобильное приложение «Мой Грошык»:
				</li>
				<li className={styles.policy_list}>• App Store – https://apps.apple.com/by/app/грошык/id6590630708;</li>
				<li className={styles.policy_list}>
					• Google Play – https://play.google.com/store/apps/details?id=by.groshik.app&hl=ru;
				</li>
				<li className={styles.policy_list}>• AppGallery – https://appgallery.huawei.com/app/C112677449.</li>
				<p>
					- посредством электронной почты, онлайн-рекрутера «Подбор персонала «Евроопт» (recruit_euroopt_bot) в
					мессенджере Telegram, сайтов для поиска работы (например, rabota.by и др.), телефонного звонка в контактный
					центр (на горячую линию) Оператора (в ходе телефонного разговора);
				</p>{' '}
				<p>- посредством аккаунтов Оператора в социальных сетях и мессенджерах в сети Интернет;</p>
				<p> - посредством проведения аудио- и (или) видеозаписи;</p>
				<p>
					{' '}
					- путем заполнения (направления) субъектом персональных данных письменных анкет (резюме) соискателей
					(кандидатов) на трудоустройство;
				</p>
				<p>
					{' '}
					- путем заполнения (направления) субъектом персональных данных письменных заявлений на получение бонусной
					карты «Еплюс. Социальная» (назначение бонусной карте «Еплюс» типа «Еплюс. Социальная»);
				</p>
				<p>
					{' '}
					- от субъектов персональных данных, с которыми Оператором заключены либо будут заключены (планируются)
					гражданско-правовые сделки (договоры);
				</p>
				<p>
					{' '}
					- от субъектов персональных данных, предоставивших Оператору персональные данные путем направления письменных
					(в том числе внесенных в книгу замечаний и предложений) и электронных обращений, а также в ходе личного
					приема;
				</p>
				<p> - от субъектов персональных данных – аффилированных лиц Оператора;</p>
				<p> - от субъектов персональных данных;</p>
				<p> – посетителей объектов Оператора, находящихся под охраной.</p>
				<p>
					1.5. Политика является общедоступной и размещается в глобальной компьютерной сети Интернет на следующих сайтах
					Оператора: https://ir.eurotorg.by/ru, https://invest.evroopt.by, https://e-account.by, https://evroopt.by,
					https://eplus.by, https://igra.evroopt.by, https://flowers.evroopt.by, https://fermer.evroopt.by,
					https://hitdiscount.by, https://groshyk.by, https://ecity.evroopt.by, https://game.evroopt.by,
					https://hmelnov.by, https://sertifikat.evroopt.by, https://contractor-evroopt.by, https://igra.groshyk.by,
					https://e-rabota.by, https://bonsticks.evroopt.by (далее, совместно и по отдельности – Сайт) и
					https://et.yamigom-store.by, а также в мобильных приложениях «Еплюс», «Мой Грошык» и «ЯМИГОМ – доставка
					продуктов».
				</p>
				<p>1.6. В настоящей Политике используются термины и их определения в значении, предусмотренном Законом.</p>
				<p>
					1.7. Политика может быть изменена Оператором в любое время в одностороннем порядке без предварительного
					уведомления субъектов персональных данных. Новая редакция Политики вступает в силу с момента ее утверждения.
				</p>
				<p>
					1.8. Вопросы обработки персональных данных, которые не отражены в Политике, регулируются действующим
					законодательством Республики Беларусь.
				</p>
				<div className={styles.policy_center}>
					<b>2. ПОРЯДОК И УСЛОВИЯ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ. УПОЛНОМОЧЕННЫЕ ЛИЦА</b>
				</div>
				<p>
					2.1. Обработка персональных данных Оператором осуществляется в соответствии с требованиями законодательства
					Республики Беларусь. Оператор с уважением относится к соблюдению прав субъектов персональных данных.
				</p>
				<p>
					2.2. Обработка персональных данных осуществляется с согласия субъектов персональных данных, а также на иных
					правовых основаниях, предусмотренных Законом и иными законодательными актами Республики Беларусь.
				</p>
				<p>
					2.3. Оператор осуществляет обработку персональных данных как с использованием средств автоматизации (в том
					числе в информационных системах и ресурсах), так и без использования таких средств, если при этом
					обеспечиваются поиск персональных данных и (или) доступ к ним по определенным критериям (картотеки, списки,
					базы данных, журналы и другое).
				</p>
				<p>
					2.4. Оператор устанавливает цели обработки персональных данных, категории субъектов персональных данных, чьи
					персональные данные подвергаются обработке, перечень обрабатываемых персональных данных, правовые основания
					обработки персональных данных и сроки их хранения согласно Приложению, которое является неотъемлемой частью
					настоящей Политики.
				</p>
				<p>
					2.5. Обработка персональных данных осуществляется Оператором путем совершения любых действий (в том числе их
					совокупности), включая сбор, систематизацию, хранение, изменение, использование, обезличивание, блокирование,
					предоставление, удаление персональных данных, а также их распространение путем опубликования на Сайте и в
					аккаунтах Оператора в социальных сетях и мессенджерах в сети Интернет, в средствах массовой информации и с
					использованием средств наружной рекламы. При этом распространение персональных данных Оператором
					осуществляется только в следующих случаях: - в отношении перечня персональных данных (за исключением
					«контактного номера телефона»), категорий субъектов персональных данных, целей и правовых оснований их
					обработки, указанных в пунктах 9-12, 15-18 и 20 Приложения к настоящей Политике; - в отношении следующего
					перечня персональных данных: «фамилия», «инициалы», «игровой код», «сведения о выигрыше», а также категорий
					субъектов персональных данных, целей и правовых оснований их обработки, указанных в пункте 8 Приложения к
					настоящей Политике; - в целях исполнения условий заключенного гражданско-правового договора,
					предусматривающего распространение определенного перечня персональных данных субъектов персональных данных,
					указанных в таком договоре, в отношении предусмотренных договором категорий субъектов персональных данных и
					перечня персональных данных, а также правовых оснований их обработки, указанных в пункте 37 Приложения к
					настоящей Политике.
				</p>
				<p>
					2.6. Предоставляя свое согласие на обработку персональных данных Оператору субъекты персональных данных
					соглашаются на совершение Оператором в соответствии с настоящей Политикой следующих действий с персональными
					данными как с использованием средств автоматизации, так и без их использования: сбор, систематизацию,
					хранение, изменение, использование, обезличивание, блокирование, предоставление, в том числе уполномоченным
					лицам (включая трансграничную передачу уполномоченным лицам), удаление персональных данных. В случаях, если
					обработка персональных данных предполагает их распространение, Оператор при получении согласия от субъекта
					персональных данных на такую обработку отдельно указывает об этом, а также указывает конкретные условия, при
					которых возможно такое действие.
				</p>
				<p>
					2.7. Отказ в предоставлении согласия по одной или нескольким целям обработки персональных данных, а также
					отзыв ранее предоставленного согласия делает невозможным обработку Оператором персональных данных по
					соответствующим целям, и, как следствие, влечет невозможность оказания субъекту персональных данных услуг,
					соответствующих целям такой обработки, оказание которых предполагает обладание Оператором определенными
					персональными данными субъекта.
				</p>
				<p>
					2.8. Оператор ограничивает обработку персональных данных достижением конкретных, заранее заявленных законных
					целей.
				</p>
				<p>
					2.9. Оператор осуществляет обработку только тех персональных данных, которые необходимы для выполнения
					заявленных целей, и не допускает их избыточной обработки.
				</p>
				<p>
					2.10. Обработка персональных данных носит прозрачный характер. Субъекту персональных данных в случаях,
					предусмотренных Законом, предоставляется соответствующая информация, касающаяся обработки его персональных
					данных.
				</p>
				<p>
					2.11. Оператор принимает меры по обеспечению достоверности обрабатываемых им персональных данных, при
					необходимости обновляет их.
				</p>
				<p>
					2.12. Обработка специальных персональных данных осуществляется с принятием комплекса мер, направленных на
					предупреждение рисков, которые могут возникнуть при обработке таких персональных данных для прав и свобод
					субъектов персональных данных.
				</p>
				<p>2.13. Оператор осуществляет обработку персональных данных с привлечением уполномоченных лиц.</p>
				<p>
					2.14. Наименование и местонахождение уполномоченных лиц, которым Оператор вправе поручить обработку
					персональных данных от имени Оператора или в его интересах:
				</p>
				<p>
					- ООО «Яскрава» (220073, г. Минск, ул. Ольшевского, д. 22, оф. 902), с целью обеспечения участия субъектов
					персональных данных, зарегистрированных в личном кабинете на сайте https://e-account.by, в том числе через
					иные Сайты или посредством мобильного приложения, в рекламных играх и мероприятиях Оператора и его партнеров;
				</p>
				<p>
					- ООО «Свежие идеи» (220090, г. Минск, ул. Олешева, д. 9, пом. 5), с целью обеспечения участия субъектов
					персональных данных, зарегистрированных в личном кабинете на сайте https://e-account.by, в том числе через
					иные Сайты или посредством мобильного приложения, в рекламных играх и мероприятиях Оператора и его партнеров;
				</p>
				<p>
					- ЗАО «Второй национальный телеканал» (220029, г. Минск, ул. Коммунистическая, д. 6, ком. 205), с целью
					выполнения работ по видеосъемке (записи) и трансляции (опубликованию) проводимых розыгрышей рекламной игры
					«Удача в придачу!», а также созданию и трансляции (опубликованию) рекламных и иных видеороликов о данной
					рекламной игре;
				</p>
				<p>
					- ООО «СМС ТРАФИК-БЕЛ» (220073, г. Минск, ул. Скрыганова, д. 6, корп. 1, пом. 9, ком. 389), с целью
					осуществления отправки информационных (рекламных, новостных и других) сообщений посредством SMS и Viber, в том
					числе SMS- сообщений, связанных с регистрацией и использованием личного кабинета на сайте
					https://e-account.by, верификацией номера телефона, SMS и Viber-сообщений, связанных с проведением
					маркетинговых и (или) рекламных мероприятий (акций, конкурсов, игр и других мероприятий), а также подбором
					персонала с использованием системы Skillaz (для коммуникации с кандидатами на вакансии);
				</p>
				<p>
					- СООО «Мобильные ТелеСистемы» (220012, г. Минск, пр-т Независимости, д. 95, пом. 4), с целью осуществления
					отправки информационных (рекламных, новостных и других) сообщений посредством SMS и Viber, в том числе SMS-
					сообщений, связанных с регистрацией и использованием личного кабинета на сайте https://e-account.by,
					верификацией номера телефона, SMS и Viber-сообщений, связанных с проведением маркетинговых и (или) рекламных
					мероприятий (акций, конкурсов, игр и других мероприятий);
				</p>
				<p>
					- ООО «Инфосинтез» (220069, г. Минск, пр-т Дзержинского, д. 3Б, оф. 122), с целью осуществления отправки
					посредством электронной почты (e-mail) информационных (рекламных, новостных и других) сообщений, а также
					ответов на обращения (вопросы, отзывы), поступившие в виде электронного сообщения через форму «Обратная связь»
					на Сайте;
				</p>
				<p>
					- ООО «СКИЛАЗ» (121205, Российская Федерация, г. Москва, вн.тер.г. муниципальный округ Можайский, территория
					Сколково Инновационного Центра, ул. Нобеля, д. 5, этаж 1, пом. III, рабочее место 17), с целью обеспечения
					автоматизации процесса подбора персонала с использованием системы Skillaz, включая осуществление отправки
					посредством электронной почты (e-mail) информационных сообщений для коммуникации с кандидатами на вакансии;
				</p>
				<p>
					- ООО «СМАРТ ПЭЙ КОМПАНИ» (220002, г. Минск, пр-т Машерова, д. 25, оф. 611), с целью обеспечения
					функционирования систем мобильного приложения «Еплюс» и дистанционного обслуживания с использованием
					встроенной в него системы бесконтактного осуществления платежей Smart Pay (далее – система Smart Pay), а также
					осуществления отправки информационных (рекламных, новостных и других) сообщений посредством Push, в том числе
					сообщений, связанных с регистрацией и использованием личного кабинета на сайте https://e-account.by
					посредством мобильного приложения «Еплюс», обращением бонусной карты и бонусов, обеспечением функционирования
					систем мобильного приложения «Еплюс» и дистанционного обслуживания с использованием встроенной в него системы
					Smart Pay (включая аутентификацию пользователей мобильного приложения);
				</p>
				<p>
					- Зубарик Олег Владимирович, являющийся плательщиком налога на профессиональный доход (222827, Минская
					область, г. Марьина Горка), с целью оказания услуг по настройке программного обеспечения обработки вызовов и
					иной информации, поступающих на телефоны контактного центра (горячей линии) Оператора, а также колл-центра по
					подбору персонала;
				</p>
				<p>
					- ООО «Надежные программы» (220006, г. Минск, ул. Аранская, д. 8, блок 1, 4 этаж), с целью оказания услуг по
					администрированию размещенных серверов для обеспечения их стабильной и безопасной работы, а также услуг
					защищенного облачного хостинга (включая размещение (хранение) информационных систем (ресурсов) Оператора,
					содержащих персональные данные, а также резервных копий таких информационных систем (ресурсов), обеспечение
					удаленного доступа к данным информационным системам (ресурсам));
				</p>
				<p>
					- ООО «Медиа Крю» (220004, г. Минск, ул. Романовская Слобода, д. 3, 3 этаж), с целью оказания услуг по
					созданию аудиовизуальных произведений (видеороликов) с участием привлекаемых для съемки актеров.
				</p>
				<p>
					2.15. Уполномоченные лица, осуществляющие обработку персональных данных на основании заключаемого с Оператором
					договора (соглашения) об обработке персональных данных (поручения Оператора), обязуются соблюдать принципы,
					правила обработки и защиты персональных данных, предусмотренные Законом. Для каждого уполномоченного лица в
					договоре (соглашении) об обработке персональных данных определяются: цели обработки, перечень действий с
					персональными данными, которые будут совершаться таким лицом, устанавливаются его обязанности по соблюдению
					конфиденциальности и обеспечению защиты персональных данных в процессе их обработки, указываются необходимые
					меры по обеспечению защиты персональных данных в соответствии с Законом. 2.16. Если для обработки персональных
					данных по поручению Оператора необходимо получение согласия субъекта персональных данных, такое согласие
					получает Оператор.
				</p>
				<p>
					2.17. Ответственность перед субъектом персональных данных за действия уполномоченного лица, которому Оператор
					поручил обработку персональных данных, несет Оператор.
				</p>
				<p>
					2.18. Персональные данные могут быть переданы третьим лицам, в том числе государственным органам и
					организациям, только по основаниям и в порядке, установленным законодательством Республики Беларусь.
				</p>
				<p>
					2.19. Хранение персональных данных Оператором осуществляется в форме, позволяющей идентифицировать субъекта
					персональных данных, не дольше, чем этого требуют заявленные цели обработки персональных данных.
				</p>
				2.20. Оператор определяет сроки хранения документов, образующихся в процессе его деятельности, в которых
				содержатся персональные данные субъектов, в соответствии с Перечнем типовых документов, образующихся в процессе
				деятельности государственных органов, иных организаций и индивидуальных предпринимателей, с указанием сроков
				хранения, установленным постановлением Министерства юстиции Республики Беларусь от 24.05.2012 № 140 «О перечне
				типовых документов», а также иными актами законодательства.
				<p>
					2.21. Если срок хранения персональных данных субъектов персональных данных не установлен законодательством
					Республики Беларусь, такой срок устанавливается Оператором самостоятельно исходя из целей обработки
					персональных данных и специфики его деятельности.
				</p>
				<p>
					2.22. В случаях утраты или несанкционированного разглашения (распространения) персональных данных Оператор
					информирует субъекта персональных данных о произошедшей утрате или несанкционированном разглашении
					(распространении) персональных данных.
				</p>
				<p>
					2.23. Оператор в соответствии с Законом принимает необходимые правовые, организационные и технические меры по
					обеспечению защиты персональных данных от несанкционированного или случайного доступа к ним, изменения,
					блокирования, копирования, распространения, предоставления, удаления персональных данных, а также от иных
					неправомерных действий в отношении персональных данных.
				</p>
				<div className={styles.policy_center}>
					<b>3. ТРАНСГРАНИЧНАЯ ПЕРЕДАЧА ПЕРСОНАЛЬНЫХ ДАННЫХ</b>
				</div>
				<p>
					3.1. Оператор может передавать персональные данные лицам, находящимся в иностранных государствах
					(трансграничная передача персональных данных), в которых обеспечивается надлежащий уровень защиты прав
					субъектов персональных данных. Перечень таких государств установлен приказом Национального центра защиты
					персональных данных Республики Беларусь от 15.11.2021 № 14 «О трансграничной передаче персональных данных».
				</p>
				<p>
					3.2. Оператор с использованием средств автоматизации осуществляет трансграничную передачу персональных данных
					на территорию Российской Федерации уполномоченному лицу – ООО «СКИЛАЗ» (121205, Российская Федерация, г.
					Москва, вн.тер.г. муниципальный округ Можайский, территория Сколково Инновационного Центра, ул. Нобеля, д. 5,
					этаж 1, пом. III, рабочее место 17) с целью обеспечения автоматизации процесса подбора персонала с
					использованием системы Skillaz, включая осуществление отправки посредством электронной почты (e-mail)
					информационных сообщений для коммуникации с кандидатами на вакансии (в отношении категорий субъектов
					персональных данных и перечня обрабатываемых персональных данных, а также в соответствии с целями их обработки
					и правовыми основаниями для такой передачи (обработки), которые указаны в пунктах 31 и 32 Приложения к
					настоящей Политике). На территории Российской Федерации обеспечивается надлежащий уровень защиты прав
					субъектов персональных данных (определен уполномоченный орган по защите прав субъектов персональных данных,
					приняты нормативные правовые акты в области персональных данных и их защиты).
				</p>
				<p>
					3.3. Оператор с целью осуществления посредством Viber отправки информационных (рекламных, новостных и других)
					сообщений, в том числе сообщений, связанных с проведением маркетинговых и (или) рекламных мероприятий (акций,
					конкурсов, игр и других мероприятий), подбором персонала с использованием системы Skillaz (для коммуникации с
					кандидатами на вакансии), осуществляет трансграничную передачу следующих персональных данных (в отношении
					категорий субъектов персональных данных, а также в соответствии с целями их обработки и правовыми основаниями
					для такой передачи (обработки), которые указаны в пунктах 8, 13, 31 и 32 Приложения к настоящей Политики):
					номера телефона – оператору сервиса обмена электронными сообщениями Viber Media S.à r.l., в том числе в
					иностранные государства, на территории которых не обеспечивается надлежащий уровень защиты прав субъектов
					персональных данных, поскольку коммуникационные серверы Viber Media S.à r.l. расположены в этих государствах.
				</p>
				<p>
					3.4. Оператор с целью осуществления посредством электронной почты отправки информационных (рекламных,
					новостных и других) сообщений, а также ответов на обращения (вопросы, отзывы), поступившие в виде электронного
					сообщения через форму «Обратная связь» на Сайте, осуществляет трансграничную передачу следующих персональных
					данных (в отношении категорий субъектов персональных данных, а также в соответствии с целями их обработки и
					правовыми основаниями для такой передачи (обработки), которые указаны в пунктах 13 и 19 Приложения к настоящей
					Политики): адреса электронной почты – почтовым сервисам обмена электронными сообщениями, в случае, если
					предоставленный субъектом персональных данных адрес электронной почты принадлежит почтовому сервису,
					коммуникационные серверы которого расположены за пределами Республики Беларусь, в том числе в иностранные
					государства, на территории которых не обеспечивается надлежащий уровень защиты прав субъектов персональных
					данных, поскольку указанные серверы отдельных почтовых сервисов расположены в этих государствах.
				</p>
				<p>
					3.5. Оператор с целью размещения (опубликования) в своих аккаунтах в социальных сетях и мессенджерах в сети
					Интернет фото- и видеоматериалов, а также информационных материалов, содержащих персональные данные, включая
					сведения о победителях маркетинговых и (или) рекламных мероприятий (розыгрышей товаров, конкурсов и других),
					проведенных в аккаунтах ООО «Евроторг» в социальных сетях и мессенджерах в сети Интернет, осуществляет
					трансграничную передачу полученных персональных данных иностранным компаниям – собственникам социальных сетей
					и мессенджеров (Instagram, TikTok, Facebook, ВКонтакте, Одноклассники, Telegram, Viber, видеохостингов YouTube
					и RuTube), в том числе на территорию Российской Федерации, а также на территорию иностранных государств, в
					которых не обеспечивается надлежащий уровень защиты прав субъектов персональных данных, поскольку серверы, на
					которых размещены отдельные социальные сети и мессенджеры в сети Интернет, расположены в таких иностранных
					государствах, а именно:
				</p>
				<p>
					- в отношении следующего перечня персональных данных: «фамилия», «инициалы», «игровой код», «сведения о
					выигрыше», а также категорий субъектов персональных данных и в соответствии с правовыми основаниями для такой
					передачи (обработки), которые указаны в пункте 8 Приложения к настоящей Политике;
				</p>
				<p>
					- в отношении категорий субъектов персональных данных, перечня персональных данных (за исключением
					«контактного номера телефона») и в соответствии с правовыми основаниями для такой передачи (обработки),
					которые указаны в пунктах 9-12, 16-18 Приложения к настоящей Политики;
				</p>
				<p>
					- в отношении следующего перечня персональных данных: «название профиля (имя) пользователя (аккаунта),
					зарегистрированного в социальной сети», «имя пользователя в мессенджере», «сведения о выигрыше», а также
					категорий субъектов персональных данных и в соответствии с правовыми основаниями для такой передачи
					(обработки), которые указаны в пункте 15 Приложения к настоящей Политике;
				</p>
				<p>
					- в отношении предусмотренных договором категорий субъектов персональных данных и перечня персональных данных,
					а также в соответствии с правовыми основаниями для такой передачи (обработки), которые указаны в пункте 37
					Приложения к настоящей Политике, в целях исполнения условий заключенного гражданско-правового договора,
					предусматривающего распространение определенного перечня персональных данных субъектов персональных данных,
					указанных в таком договоре. С дополнительной информацией о порядке обработки персональных данных Viber Media
					S.à r.l. можно ознакомиться на официальном сайте компании, размещенном в сети Интернет по адресу:
					https://www.viber.com/ru/terms/viber-privacy-policy/, а с дополнительной информацией о порядке обработки
					персональных данных почтовыми сервисами, в социальных сетях и мессенджерах – на официальных сайтах компаний,
					владеющих такими сервисами (Mail.ru, Gmail.com и др.), а также на сайтах социальных сетей и мессенджеров.
				</p>
				<p>
					3.6. Принимая во внимание, что Оператор может осуществлять трансграничную передачу персональных данных на
					территорию иностранных государств, в которых в соответствии с законодательством Республики Беларусь не
					обеспечивается надлежащий уровень защиты прав субъектов персональных данных, информируем субъектов
					персональных данных о следующих рисках, возникающих в связи с отсутствием такого уровня защиты:
				</p>
				<p>отсутствие уполномоченного органа по защите прав субъектов персональных данных;</p>
				<p>
					отсутствие (фрагментарность) специального законодательства, регулирующего обработку персональных данных;
					неопределенность правовых оснований обработки персональных данных;
				</p>
				<p>отсутствие (ограниченность) прав субъектов персональных данных;</p>
				<p>отсутствие (ограничение) мер ответственности за нарушения прав субъектов персональных данных;</p>
				<p>риск широкого доступа к персональным данным правоохранительных органов различных государств;</p>
				<p>риск применения незаконных способов доступа к персональным данным и их незаконного использования.</p>
				<div className={styles.policy_center}>
					<b>4. СПОСОБЫ ПОЛУЧЕНИЯ СОГЛАСИЯ СУБЪЕКТА ПЕРСОНАЛЬНЫХ ДАННЫХ НА ОБРАБОТКУ ПЕРСОНАЛЬНЫХ ДАННЫХ</b>
				</div>
				<p>
					4.1. Согласие субъекта персональных данных представляет собой свободное, однозначное, информированное
					выражение его воли, посредством которого он разрешает обработку своих персональных данных.
				</p>
				<p>
					4.2. Согласие субъекта персональных данных может быть получено Оператором в письменной форме, в виде
					электронного документа или в иной электронной форме.
				</p>
				<p>4.3. В иной электронной форме согласие субъекта персональных данных Оператор получает посредством:</p>
				<p>
					- проставления субъектом персональных данных напротив каждой из целей обработки персональных данных
					соответствующей отметки: на Сайте и в мобильном приложении (в том числе при регистрации и использовании
					личного кабинета на сайте https://e-account.by), на одностраничном сайте (лендинге) системы Skillaz,
					обеспечивающей автоматизацию процесса подбора персонала (по адресу: https://candidate.skillaz.ru), в
					онлайн-рекрутере «Подбор персонала «Евроопт» (recruit_euroopt_bot) в ходе онлайн-собеседования (с
					использованием мессенджера Telegram);
				</p>
				<p>
					- выбора и нажатия субъектом персональных данных соответствующей цифры набора номера в телефоне (при обращении
					(звонке) на телефоны контактного центра (горячей линии) Оператора);
				</p>
				<p>
					- направления субъектом персональных данных личного электронного сообщения о предоставлении своего согласия в
					аккаунт Оператора в социальной сети (например, в Direct в Instagram и др.) или в мессенджере в сети Интернет,
					на адрес электронной почты Оператора.
				</p>
				<p>
					4.4. До предоставления согласия на обработку персональных данных субъекту персональных данных рекомендуется
					ознакомиться с настоящей Политикой.
				</p>
				<div className={styles.policy_center}>
					<b>5. ПРАВА СУБЪЕКТА ПЕРСОНАЛЬНЫХ ДАННЫХ И МЕХАНИЗМ ИХ РЕАЛИЗАЦИИ</b>
				</div>
				<p>5.1. Субъекты персональных данных обладают следующими правами:</p>
				<div className={styles.policy_table}>
					<table>
						<tbody>
							<tr>
								<td>
									<p>№</p>
								</td>
								<td>
									<p>Право</p>
								</td>
								<td>
									<p>Содержание права</p>
								</td>
								<td>
									<p>Последствия его реализации</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>1</p>
								</td>
								<td>
									<p>Право на отзыв предоставленного ранее согласия на обработку персональных данных </p>
								</td>
								<td>
									<p>
										Субъект персональных данных вправе в любое время без объяснения причин отозвать свое ранее
										предоставленное Оператору согласие на обработку персональных данных посредством подачи Оператору
										заявления в письменной форме, либо в виде электронного документа, либо в форме, посредством которой
										получено его согласие. Вместе с тем, право на отзыв согласия не может быть реализовано в случаях,
										когда обработка персональных данных осуществляется на иных правовых основаниях (например, при
										получении персональных данных на основании договора в целях совершения установленных им действий или
										когда законодательными актами прямо предусматривается обработка персональных данных без согласия
										субъекта){' '}
									</p>
								</td>
								<td>
									<p>
										Оператор в пятнадцатидневный срок после получения заявления субъекта персональных данных в
										соответствии с его содержанием прекратит обработку персональных данных, осуществит их удаление и
										уведомит об этом субъекта персональных данных, за исключением случаев, когда Оператор вправе
										продолжить обработку персональных данных при наличии оснований, предусмотренных законодательными
										актами. При отсутствии технической возможности удаления персональных данных Оператор примет меры по
										недопущению дальнейшей обработки персональных данных, включая их блокирование, и уведомит об этом
										субъекта персональных данных в тот же срок.
									</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>2</p>
								</td>
								<td>
									<p>Право на получение информации, касающейся обработки персональных данных</p>
								</td>
								<td>
									<p>
										Субъект персональных данных имеет право на получение информации, касающейся обработки своих
										персональных данных, содержащей:
									</p>
									<p>
										- наименование (фамилию, собственное имя, отчество (если таковое имеется)) и место нахождения (адрес
										места жительства (места пребывания)) оператора;
									</p>
									<p>- подтверждение факта обработки персональных данных оператором (уполномоченным лицом);</p>
									<p>- его персональные данные и источник их получения;</p>
									<p>- правовые основания и цели обработки персональных данных;</p>
									<p>- срок, на который дано его согласие;</p>
									<p>
										- наименование и место нахождения уполномоченного лица, которое является государственным органом,
										юридическим лицом Республики Беларусь, иной организацией, если обработка персональных данных
										поручена такому лицу;
									</p>
									<p>- иную информацию, предусмотренную законодательством.</p>
								</td>
								<td>
									<p>
										Оператор в течение 5 рабочих дней после получения заявления субъекта персональных данных предоставит
										в доступной форме запрашиваемую информацию, либо уведомит о причинах отказа в ее предоставлении.
									</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>3</p>
								</td>
								<td>
									<p>Право на внесение изменений в свои персональные данные </p>
								</td>
								<td>
									<p>
										Субъект персональных данных вправе требовать от Оператора внести изменения в свои персональные
										данные в случае, если они являются неполными, устаревшими или неточными. В этих целях субъект
										персональных данных прилагает к заявлению соответствующие документы и (или) их заверенные в
										установленном порядке копии, подтверждающие необходимость внесения изменений.
									</p>
								</td>
								<td>
									<p>
										Оператор в пятнадцатидневный срок после получения заявления субъекта персональных данных вносит
										соответствующие изменения в его персональные данные и уведомляет об этом субъекта персональных
										данных, либо уведомляет о причинах отказа во внесении таких изменений.
									</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>4</p>
								</td>
								<td>
									<p>Право на получение информации о предоставлении персональных данных третьим лицам </p>
								</td>
								<td>
									<p>
										Субъект персональных данных вправе получать от Оператора информацию о предоставлении своих
										персональных данных третьим лицам один раз в календарный год бесплатно.
									</p>
								</td>
								<td>
									<p>
										Оператор в пятнадцатидневный срок после получения заявления субъекта персональных данных предоставит
										ему информацию о том, какие персональные данные этого субъекта и кому предоставлялись в течение
										года, предшествовавшего дате подачи заявления, либо уведомит о причинах отказа в ее предоставлении.
									</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>5</p>
								</td>
								<td>
									<p>Право требовать прекращения обработки персональных данных и (или) их удаления</p>
								</td>
								<td>
									<p>
										Субъект персональных данных вправе требовать от Оператора бесплатного прекращения обработки своих
										персональных данных, включая их удаление, при отсутствии оснований для обработки персональных
										данных, предусмотренных законодательством Республики Беларусь.
									</p>
								</td>
								<td>
									<p>
										Оператор в пятнадцатидневный срок после получения заявления субъекта персональных данных прекратит
										обработку персональных данных, а также осуществит их удаление (обеспечит прекращение обработки
										персональных данных, а также их удаление уполномоченным лицом) и уведомит об этом субъекта
										персональных данных, либо откажет субъекту персональных данных в удовлетворении требований о
										прекращении обработки его персональных данных и (или) их удалении при наличии оснований для
										обработки персональных данных, предусмотренных законодательством Республики Беларусь, и уведомит об
										этом субъекта персональных данных в тот же срок. При отсутствии технической возможности удаления
										персональных данных Оператор примет меры по недопущению дальнейшей обработки персональных данных,
										включая их блокирование, и также уведомит об этом в пятнадцатидневный срок.
									</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>6</p>
								</td>
								<td>
									<p>
										Право на обжалование действий (бездействия) и решений Оператора, связанных с обработкой персональных
										данных{' '}
									</p>
								</td>
								<td>
									<p>
										Субъект персональных данных вправе обжаловать действия (бездействие) и решения Оператора, нарушающие
										его права при обработке персональных данных, в Национальный центр защиты персональных данных
										Республики Беларусь (220003, г.Минск, ул. Клары Цеткин, 24-3), в порядке, установленном
										законодательством об обращениях граждан и юридических лиц.
									</p>
								</td>
								<td>
									<p>
										Национальным центром защиты персональных данных Республики Беларусь жалоба рассматривается в
										порядке, установленном законодательством об обращениях граждан и юридических лиц.
									</p>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<p>
					5.2. Для реализации прав, указанных в пункте 5.1 Политики, субъекту персональных данных необходимо направить
					Оператору соответствующее заявление одним из следующих способов:
				</p>
				<p>- в письменной форме по адресу: 220019, г. Минск, ул. Монтажников, д. 2;</p>
				<p>
					- в виде электронного документа, содержащего электронную цифровую подпись субъекта персональных данных, на
					адрес электронной почты: dpo@eurotorg.by.
				</p>
				<p>
					5.3. Для реализации права на отзыв согласия субъекту персональных данных необходимо направить Оператору
					соответствующее заявление в порядке, установленном пунктом 5.2 Политики.
				</p>
				<p>
					В случае, если согласие субъектом персональных данных было предоставлено в иной электронной форме, право на
					отзыв согласия может быть также реализовано в форме, посредством которой получено такое согласие:
				</p>
				<p>
					- путем снятия проставленных субъектом отметок (отметки) о даче согласия согласно выбранным целям обработки
					персональных данных в соответствующих окнах (окне) в личном кабинете на сайте https://e-account.by, в том
					числе через иные Сайты или посредством мобильного приложения (для целей обработки, указанных в пунктах 8-14
					Приложения к настоящей Политике);
				</p>
				<p>
					- путем авторизации (входа) в личном кабинете на сайте https://e-account.by, в том числе через иные Сайты или
					посредством мобильного приложения, и его последующего удаления посредством нажатия «Удалить личный кабинет»
					(для целей обработки, указанных в пунктах 1-3, 5, 6 Приложения к настоящей Политике);
				</p>
				<p>
					- путем авторизации (входа) в личном кабинете на сайте https://ir.eurotorg.by/ru в разделе «Центр результатов»
					и его последующего удаления посредством нажатия «Удалить личный кабинет» (для цели обработки, указанной в
					пункте 43 Приложения к настоящей Политике);
				</p>
				<p>
					- посредством направления электронного сообщения, содержащего фамилию, собственное имя, отчество (если таковое
					имеется), дату рождения, адрес места жительства (места пребывания) и изложение сути требований субъекта
					персональных данных, на адрес электронной почты dpo@eurotorg.by (для целей обработки, указанных в пунктах 31 и
					32 Приложения к настоящей Политике);
				</p>
				<p>
					- посредством направления электронного сообщения, содержащего собственное имя и номер телефона, а также
					фамилию и отчество (если таковое имеется), которые были указаны субъектом персональных данных для получения
					обратной связи в форме «Обратная связь» на Сайте, и изложение сути его требований, на адрес электронной почты
					dpo@eurotorg.by (для цели обработки, указанной в пункте 19 Приложения к настоящей Политике);
				</p>
				<p>
					- посредством направления личного электронного сообщения в аккаунт Оператора в социальной сети (например, в
					Direct в Instagram и др.) или в мессенджере в сети Интернет, либо на адрес электронной почты Оператора,
					содержащего название профиля (имя) пользователя (аккаунта), зарегистрированного в социальной сети, или имя
					пользователя в мессенджере, а также адрес электронной почты (только для пользователей социальной сети
					YouTube), которые были указаны субъектом персональных данных при предоставлении своего согласия на
					опубликование сведений о нем, как о победителе маркетинговых и (или) рекламных мероприятий (розыгрышей
					товаров, конкурсов и других), в аккаунтах ООО «Евроторг» в социальных сетях и мессенджерах в сети Интернет, и
					изложение сути его требований (для цели обработки, указанной в пункте 15 Приложения к настоящей Политике).
				</p>
				<p>
					5.4. Право на отзыв согласия не может быть реализовано, если обработка персональных данных осуществляется на
					основании договора либо иных правовых основаниях, предусмотренных Законом и иными законодательными актами.
				</p>
				<p>
					5.5. Заявление субъекта персональных данных о реализации одного или нескольких перечисленных выше прав должно
					содержать: фамилию, собственное имя, отчество (если таковое имеется) субъекта персональных данных, адрес его
					места жительства (места пребывания); дату рождения субъекта персональных данных; идентификационный номер
					субъекта персональных данных, при отсутствии такого номера – номер документа, удостоверяющего личность
					субъекта персональных данных, в случаях, если эта информация указывалась субъектом персональных данных при
					даче своего согласия оператору или обработка персональных данных осуществляется без согласия субъекта
					персональных данных; изложение сути требований субъекта персональных данных; личную подпись (для заявления в
					письменной форме) либо электронную цифровую подпись (для заявления в виде электронного документа) субъекта
					персональных данных.
				</p>
				<p>
					5.6. Оператор не рассматривает заявления субъектов персональных данных, направленные иными способами (по
					телефону, факсу и т.п.).
				</p>
				<p>
					5.7. Ответ на заявление Оператором направляется субъекту персональных данных в форме, соответствующей форме
					подачи заявления, если в самом заявлении не указано иное.
				</p>
				<p>
					5.8. Лицом, ответственным у Оператора за осуществление внутреннего контроля за обработкой персональных данных,
					является специалист по внутреннему контролю за обработкой персональных данных. Субъект персональных данных
					может обратится за содействием в реализации своих прав к указанному лицу, направив электронное сообщение на
					адрес электронной почты dpo@eurotorg.by.
				</p>
				<p>
					5.9. Подразумевается, что субъект персональных данных предоставляет Оператору достоверную информацию о своих
					персональных данных.
				</p>
				<p className={styles.policy_center}>
					<b>6. СООПЕРАТОР</b>
				</p>
				<p>
					6.1. Оператор вправе передавать персональные данные субъекта персональных данных совместному оператору – ЗАО
					«Интернет-магазин Евроопт» (далее – Сооператор), совместно с Оператором осуществляющему обработку персональных
					данных на основании абзаца восьмого статьи 1 Закона.
				</p>
				<p>6.2. Контактные данные Сооператора:</p>
				<p>
					наименование: закрытое акционерное общество «Интернет-магазин Евроопт», адрес места нахождения: 220019,
					Минская область, Минский район, Щомыслицкий с/с, Западный промузел, ТЭЦ-4, каб. 229, почтовый адрес: 220019,
					г. Минск, а/я 19,
				</p>
				<p>адрес в сети Интернет: https://edostavka.by, адрес электронной почты (e-mail):</p>
				<p>obr@e-dostavka.by, телефон: +375 29 1066358.</p>
				<p>
					6.3. Оператор совместно с Сооператором обрабатывает персональные данные, изложенные в пункте 8 Приложения к
					настоящей Политике, в соответствии с указанными там целями обработки, категориями субъектов персональных
					данных, правовыми основаниями обработки и сроками хранения персональных данных.
				</p>
				<p>
					6.4. Сооператор осуществляет обработку персональных данных как с использованием средств автоматизации (в том
					числе в информационных системах и ресурсах), так и без использования таких средств, если при этом
					обеспечиваются поиск персональных данных и (или) доступ к ним по определенным критериям (картотеки, списки,
					базы данных, журналы и другое), путем совершения любых действий (в том числе их совокупности), включая сбор,
					систематизацию, хранение, изменение, использование, обезличивание, блокирование, предоставление и удаление
					персональных данных.
				</p>
				<p>
					6.5. Предоставляя свое согласие на обработку персональных данных Оператору субъекты персональных данных
					выражают его для достижения конкретной цели также и Сооператору.
				</p>
				<p>
					6.6. Срок хранения персональных данных Сооператором совпадает со сроком хранения персональных данных
					Оператором для тождественной цели совместной обработки персональных данных.
				</p>
				<p>
					6.7. Для реализации прав, связанных с обработкой персональных данных Сооператором, субъекту персональных
					данных необходимо обращаться к Оператору или Сооператору, которому субъектом предоставлялось свое согласие на
					обработку персональных данных, в порядке, определенном разделом 5 настоящей Политики.
				</p>
				<p className={styles.policy_right}>
					<b>
						Приложение <br />к Политике ООО «Евроторг» в отношении обработки персональных данных
					</b>
				</p>
				<p>
					<b>
						Перечень обрабатываемых ООО «Евроторг» персональных данных, цели их обработки, категории субъектов
						персональных данных, чьи данные подвергаются обработке, правовые основания обработки, сроки хранения
						персональных данных.
					</b>
				</p>
			</div>

			<div className={styles.policy_table}>
				<table>
					<thead>
						<tr>
							<th>
								<p className={styles.policy_center}>№ п/п</p>
							</th>
							<th>
								<p className={styles.policy_center}>Цели обработки персональных данных</p>
							</th>
							<th>
								<p className={styles.policy_center}>
									Категории субъектов персональных данных, чьи данные подвергаются обработке
								</p>
							</th>
							<th>
								<p className={styles.policy_center}>Перечень обрабатываемых персональных данных</p>
							</th>
							<th>
								<p className={styles.policy_center}>Правовые основания обработки персональных данных</p>
							</th>
							<th>
								<p className={styles.policy_center}>Срок хранения персональных данных</p>
							</th>
						</tr>
					</thead>

					<tbody>
						<tr>
							<td>
								<p>1</p>
							</td>
							<td>
								<p>
									Регистрация и участие в программе лояльности «Еплюс», обращение бонусной карты и бонусов, верификация
									номера телефона
								</p>
							</td>
							<td>
								<p>
									Участники программы лояльности «Еплюс», зарегистрировавшие бонусную карту в личном кабинете на сайте
									https://e-account.by, в том числе через сайты: https://evroopt.by, https://eplus.by,
									https://igra.evroopt.by, https://game.evroopt.by, https://igra.groshyk.by,
									https://bonsticks.evroopt.by или посредством мобильных приложений «Еплюс» и «Мой Грошык» (далее
									–мобильное приложение)
								</p>
							</td>
							<td>
								<p>
									Фамилия, собственное имя, контактный номер телефона, адрес электронной почты (необязательно), номер
									бонусной карты, сведения об участии в программе лояльности «Еплюс», IP-адрес устройства, метаданные
									браузера, UUID (универсальный уникальный идентификатор)
								</p>
							</td>
							<td>
								<p>
									Согласие (ст. 5 Закона Республики Беларусь (далее – Закон) от 07.05.2021 № 99-З «О защите персональных
									данных» (далее – Закон «О защите персональных данных»)
								</p>
							</td>
							<td>
								<p>
									3 года с даты последнего использования бонусной карты или последней авторизации (входа) в личном
									кабинете, а в случае неиспользования бонусной карты и личного кабинета – 3 года с даты дачи согласия
								</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>2</p>
							</td>
							<td>
								<p>
									Регистрация и участие в программе лояльности «Еплюс. Пенсионная», обращение бонусной карты и бонусов,
									верификация номера телефона
								</p>
							</td>
							<td>
								<p>
									Участники программы лояльности «Еплюс. Пенсионная», зарегистрировавшие бонусную карту в личном
									кабинете на сайте https://e-account.by, в том числе через сайты: https://evroopt.by, https://eplus.by,
									https://igra.evroopt.by, https://game.evroopt.by, https://igra.groshyk.by,
									https://bonsticks.evroopt.by или посредством мобильного приложения
								</p>
							</td>
							<td>
								<p>
									Фамилия, собственное имя, контактный номер телефона, адрес электронной почты (необязательно), номер
									бонусной карты, сведения об участии в программе лояльности «Еплюс. Пенсионная», IP-адрес устройства,
									метаданные браузера, UUID (универсальный уникальный идентификатор)
								</p>
							</td>
							<td>
								<p>Согласие (ст. 5 Закона «О защите персональных данных»)</p>
							</td>
							<td>
								<p>
									3 года с даты последнего использования бонусной карты или последней авторизации (входа) в личном
									кабинете, а в случае неиспользования бонусной карты и личного кабинета – 3 года с даты дачи согласия
								</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>3</p>
							</td>
							<td>
								<p>
									Регистрация и участие в программе лояльности «Еплюс. Добрый процент», обращение бонусной карты и
									бонусов, верификация номера телефона
								</p>
							</td>
							<td>
								<p>
									Участники программы лояльности «Еплюс. Добрый процент», зарегистрировавшие бонусную карту в личном
									кабинете на сайте, https://e-account.by, в том числе через сайты: https://evroopt.by,
									https://eplus.by, https://igra.evroopt.by, https://game.evroopt.by, https://igra.groshyk.by,
									https://bonsticks.evroopt.by или посредством мобильного приложения
								</p>
							</td>
							<td>
								<p>
									Фамилия, собственное имя, контактный номер телефона, адрес электронной почты (необязательно), номер
									бонусной карты, сведения об участии в программе лояльности «Еплюс. Добрый процент», IP-адрес
									устройства, метаданные браузера, UUID (универсальный уникальный идентификатор)
								</p>
							</td>
							<td>
								<p>Согласие (ст. 5 Закона «О защите персональных данных»)</p>
							</td>
							<td>
								<p>
									3 года с даты последнего использования бонусной карты или последней авторизации (входа) в личном
									кабинете, а в случае неиспользования бонусной карты и личного кабинета – 3 года с даты дачи согласия
								</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>4</p>
							</td>
							<td>
								<p>
									Получение бонусной карты с типом «Еплюс. Социальная» (либо назначение бонусной карте «Еплюс», при ее
									наличии, типа «Еплюс. Социальная»)
								</p>
							</td>
							<td>
								<p>Покупатели, принадлежащие к социально уязвимым категориям населения</p>
							</td>
							<td>
								<p>
									Фамилия, собственное имя, отчество, номер бонусной карты, реквизиты документов, подтверждающих
									принадлежность к социально уязвимым категориям населения (номер и дата выдачи пенсионного
									удостоверения, серия, номер и дата выдачи удостоверения инвалида, серия, номер и дата выдачи
									удостоверения ребенка- инвалида, номер и дата выдачи удостоверения многодетной семьи, номер и дата
									выдачи справки о предоставлении государственной адресной социальной помощи в виде ежемесячного
									социального пособия, номер и дата выдачи доверенности или удостоверения на право представления
									интересов подопечного (для представителя инвалида), дата свидетельства о рождении ребенка или
									свидетельства об усыновлении (удочерении) (для законных представителей – родителей или усыновителей
									(удочерителей)), номер и дата выдачи удостоверения на право представления интересов подопечного (для
									законных представителей – опекунов, попечителей)), вид документа, удостоверяющего личность, который
									предъявляется при получении бонусной карты (паспорт или идентификационная карта гражданина Республики
									Беларусь, либо вид на жительство в Республике Беларусь)
								</p>
							</td>
							<td>
								<p>Согласие (ст. 5 Закона «О защите персональных данных»)</p>
							</td>
							<td>
								<p>3 года с даты дачи согласия</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>5</p>
							</td>
							<td>
								<p>
									Регистрация и участие в программе лояльности «Еплюс. Социальная», обращение бонусной карты и бонусов,
									верификация номера телефона
								</p>
							</td>
							<td>
								<p>
									Участники программы лояльности «Еплюс. Социальная», зарегистрировавшие бонусную карту в личном
									кабинете на сайте https://e-account.by, в том числе через сайты: https://evroopt.by, https://eplus.by,
									https://igra.evroopt.by, https://game.evroopt.by, https://igra.groshyk.by,
									https://bonsticks.evroopt.by или посредством мобильного приложения
								</p>
							</td>
							<td>
								<p>
									Фамилия, собственное имя, контактный номер телефона, адрес электронной почты (необязательно), номер
									бонусной карты, сведения об участии в программе лояльности «Еплюс. Социальная», IP-адрес устройства,
									метаданные браузера, UUID (универсальный уникальный идентификатор)
								</p>
							</td>
							<td>
								<p>Согласие (ст. 5 Закона «О защите персональных данных»)</p>
							</td>
							<td>
								<p>
									3 года с даты последнего использования бонусной карты или последней авторизации (входа) в личном
									кабинете, а в случае неиспользования бонусной карты и личного кабинета – 3 года с даты дачи согласия
								</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>6</p>
							</td>
							<td>
								<p>
									Создание (регистрация) и использование личного кабинета на сайте https://e-account.by, в том числе
									через сайты: https://evroopt.by, https://eplus.by, https://igra.evroopt.by, https://game.evroopt.by,
									https://igra.groshyk.by, https://bonsticks.evroopt.by или посредством мобильных приложений «Еплюс» и
									«Мой Грошык», обеспечение функционирования систем мобильного приложения «Еплюс» и дистанционного
									обслуживания с использованием встроенной в него системы Smart Pay (включая аутентификацию
									пользователей мобильного приложения «Еплюс»)
								</p>
							</td>
							<td>
								<p>
									Пользователи сайтов: https://e-account.by, https://evroopt.by, https://eplus.by,
									https://igra.evroopt.by, https://game.evroopt.by, https://igra.groshyk.by,
									https://bonsticks.evroopt.by, пользователи мобильного приложения, зарегистрировавшие личный кабинет
								</p>
							</td>
							<td>
								<p>
									Фамилия, собственное имя, контактный номер телефона, адрес электронной почты (необязательно), номер
									бонусной карты (обязательно для мобильного приложения «Еплюс»), номер карты лояльности «Грошык»
									(обязательно для мобильного приложения «Мой Грошык»), сведения об участии в программах лояльности
									«Еплюс» (обязательно для мобильных приложений), ManID (уникальный идентификатор пользователя),
									IP-адрес устройства, метаданные браузера, UUID (универсальный уникальный идентификатор), сведения об
									операционной системе и ее версии на устройстве пользователя (только для мобильных приложений), марка и
									модель устройства (только для мобильных приложений)
								</p>
							</td>
							<td>
								<p>Согласие (ст. 5 Закона «О защите персональных данных»)</p>
							</td>
							<td>
								<p>
									3 года с даты последней авторизации (входа) в личном кабинете или последнего использования бонусной
									карты, а в случае неиспользования бонусной карты и личного кабинета – 3 года с даты дачи согласия
								</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>7</p>
							</td>
							<td>
								<p>
									Блокировка бонусной карты «Еплюс», перенос истории бонусной карты «Еплюс» со старой на новую бонусную
									карту, возврат бонусов на бонусную карту «Еплюс» при возврате товара
								</p>
							</td>
							<td>
								<p>Участники программ лояльности «Еплюс»</p>
							</td>
							<td>
								<p>
									Фамилия, собственное имя, отчество, контактный номер телефона, номер бонусной карты, личная подпись
								</p>
							</td>
							<td>
								<p>
									Обработка персональных данных, указанных в документе, адресованном оператору и подписанном субъектом
									персональных данных, в соответствии с содержанием такого документа (абзац 16 ст. 6 Закона «О защите
									персональных данных»)
								</p>
							</td>
							<td>
								<p>1 год с даты обращения</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>8</p>
							</td>
							<td>
								<p>
									Проведение маркетинговых и (или) рекламных мероприятий (акций, конкурсов, игр и других мероприятий){' '}
								</p>
							</td>
							<td>
								<p>
									Участники программ лояльности «Еплюс», зарегистрировавшие бонусную карту в личном кабинете на сайте
									https://e-account.by, в том числе через сайты: https://evroopt.by, https://eplus.by,
									https://igra.evroopt.by, https://game.evroopt.by, https://igra.groshyk.by,
									https://bonsticks.evroopt.by или посредством мобильного приложения
								</p>
							</td>
							<td>
								<p>
									Фамилия, собственное имя, отчество (инициалы), дата рождения (необязательно), пол (необязательно),
									количество членов семьи (необязательно), контактный номер телефона, дополнительный номер телефона
									(необязательно), адрес электронной почты (необязательно), адрес места жительства (страна, область,
									район, населенный пункт, улица, дом, корпус (при наличии), подъезд (при наличии), этаж (при наличии),
									квартира (при наличии)), номер бонусной карты, сведения об участии в программах лояльности «Еплюс»,
									игровой код, сведения о выигрыше (конкретный перечень обрабатываемых персональных данных определяется
									правилами проведения соответствующих маркетинговых и (или) рекламных мероприятий)
								</p>
							</td>
							<td>
								<p>Согласие (ст. 5, абзац 2 п. 1 ст. 9 Закона «О защите персональных данных»)</p>
							</td>
							<td>
								<p>
									3 года с даты последней авторизации (входа) в личном кабинете или последнего использования бонусной
									карты, а в случае неиспользования бонусной карты и личного кабинета – 3 года с даты дачи согласия
								</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>9</p>
							</td>
							<td>
								<p>
									Участие в качестве победителя в прямом эфире трансляции розыгрыша рекламной игры «Удача в придачу!» на
									телеканале «ОНТ», в съемке розыгрыша иных рекламных игр
								</p>
							</td>
							<td>
								<p>
									Участники программ лояльности «Еплюс», зарегистрировавшие бонусную карту в личном кабинете на сайте
									https://e-account.by, в том числе через сайты: https://evroopt.by, https://eplus.by,
									https://igra.evroopt.by, https://game.evroopt.by, https://igra.groshyk.by,
									https://bonsticks.evroopt.by или посредством мобильного приложения, ставшие победителями рекламной
									игры «Удача в придачу!» или иных рекламных игр
								</p>
							</td>
							<td>
								<p>
									Фамилия, собственное имя, отчество, контактный номер телефона, населенный пункт (район, область) места
									жительства, род занятий (занимаемая должность (профессия), место учебы), возраст, сведения о выигрыше,
									игровой код, фотоизображение, видеоизображение (видеозапись) и аудиозапись звонка победителю в прямом
									эфире трансляции розыгрыша рекламной игры «Удача в придачу!» на телеканале «ОНТ» или в ходе розыгрыша
									иных рекламных игр, видеоизображение (видеозапись) участия победителя в прямом эфире трансляции
									розыгрыша рекламной игры «Удача в придачу!» на телеканале «ОНТ» или в съемке розыгрыша иных рекламных
									игр
								</p>
							</td>
							<td>
								<p>Согласие (ст. 5, абзац 2 п. 1 ст. 9 Закона «О защите персональных данных»)</p>
							</td>
							<td>
								<p>
									До момента трансляции (опубликования) в прямом эфире на телеканале «ОНТ» розыгрыша рекламной игры
									«Удача в придачу!» или до момента опубликования на интернет-сайтах и в аккаунтах ООО «Евроторг» в
									социальных сетях и мессенджерах в сети Интернет, в средствах массовой информации розыгрыша иных
									рекламных игр
								</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>10</p>
							</td>
							<td>
								<p>
									Участие в качестве победителя рекламной игры «Удача в придачу!» или иных рекламных игр в съемке
									видеоролика о рекламной игре
								</p>
							</td>
							<td>
								<p>
									Участники программ лояльности «Еплюс», зарегистрировавшие бонусную карту в личном кабинете на сайте
									https://e-account.by, в том числе через сайты: https://evroopt.by, https://eplus.by,
									https://igra.evroopt.by, https://game.evroopt.by, https://igra.groshyk.by,
									https://bonsticks.evroopt.by или посредством мобильного приложения, ставшие победителями рекламной
									игры «Удача в придачу!» или иных рекламных игр
								</p>
							</td>
							<td>
								<p>
									Фамилия, собственное имя, отчество, контактный номер телефона, населенный пункт (район, область) места
									жительства, род занятий (занимаемая должность (профессия), место учебы), возраст, сведения о выигрыше,
									игровой код, фотоизображение, видеоизображение (видеозапись) участия победителя рекламной игры «Удача
									в придачу!» или иных рекламных игр в съемке видеоролика о рекламной игре
								</p>
							</td>
							<td>
								<p>Согласие (ст. 5, абзац 2 п. 1 ст. 9 Закона «О защите персональных данных»)</p>
							</td>
							<td>
								<p>
									До момента опубликования на интернет-сайтах и в аккаунтах ООО «Евроторг» в социальных сетях и
									мессенджерах в сети Интернет, в средствах массовой информации
								</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>11</p>
							</td>
							<td>
								<p>
									Участие в качестве победителя в трансляции (съемке) церемонии награждения победителей рекламной игры
									«Удача в придачу!» или иных рекламных игр
								</p>
							</td>
							<td>
								<p>
									Участники программ лояльности «Еплюс», зарегистрировавшие бонусную карту в личном кабинете на сайте
									https://e-account.by, в том числе через сайты: https://evroopt.by, https://eplus.by,
									https://igra.evroopt.by, https://game.evroopt.by, https://igra.groshyk.by,
									https://bonsticks.evroopt.by или посредством мобильного приложения, ставшие победителями рекламной
									игры «Удача в придачу!» или иных рекламных игр
								</p>
							</td>
							<td>
								<p>
									Фамилия, собственное имя, отчество, контактный номер телефона, населенный пункт (район, область) места
									жительства, род занятий (занимаемая должность (профессия), место учебы), возраст, сведения о выигрыше,
									игровой код, фотоизображение, видеоизображение (видеозапись) участия победителя в трансляции (съемке)
									церемонии награждения победителей рекламной игры «Удача в придачу!» или иных рекламных игр
								</p>
							</td>
							<td>
								<p>Согласие (ст. 5, абзац 2 п. 1 ст. 9 Закона «О защите персональных данных»)</p>
							</td>
							<td>
								<p>
									До момента опубликования на интернет-сайтах и в аккаунтах ООО «Евроторг» в социальных сетях и
									мессенджерах в сети Интернет, в средствах массовой информации
								</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>12</p>
							</td>
							<td>
								<p>
									Использование персональных данных победителей рекламной игры «Удача в придачу!» и иных маркетинговых и
									(или) рекламных мероприятий (конкурсов, игр и других) в рекламных материалах ООО «Евроторг»
									(размещаемых, в том числе на интернет-сайтах и в аккаунтах ООО «Евроторг» в социальных сетях и
									мессенджерах в сети Интернет, в средствах массовой информации, с использованием средств наружной
									рекламы)
								</p>
							</td>
							<td>
								<p>
									Участники программ лояльности «Еплюс», зарегистрировавшие бонусную карту в личном кабинете на сайте
									https://e-account.by, в том числе через сайты: https://evroopt.by, https://eplus.by,
									https://igra.evroopt.by, https://game.evroopt.by, https://igra.groshyk.by,
									https://bonsticks.evroopt.by или посредством мобильного приложения, ставшие победителями рекламной
									игры «Удача в придачу!» или иных маркетинговых и (или) рекламных мероприятий (конкурсов, игр и других)
								</p>
							</td>
							<td>
								<p>
									Фамилия, собственное имя, отчество, населенный пункт (район, область) места жительства, род занятий
									(занимаемая должность (профессия), место учебы), возраст, сведения о выигрыше, игровой код,
									фотоизображение, видеоизображение (видеозапись) и аудиозапись звонка победителю в прямом эфире
									трансляции розыгрыша рекламной игры «Удача в придачу!» на телеканале «ОНТ» или в ходе розыгрыша иных
									рекламных игр, видеоизображение (видеозапись) участия победителя в прямом эфире трансляции розыгрыша
									рекламной игры «Удача в придачу!» на телеканале «ОНТ» или в съемке розыгрыша иных рекламных игр,
									видеоизображение (видеозапись) участия победителя рекламной игры «Удача в придачу!» или иных рекламных
									игр в съемке видеоролика о рекламной игре, видеоизображение (видеозапись) участия победителя в
									трансляции (съемке) церемонии награждения победителей рекламной игры «Удача в придачу!» или иных
									рекламных игр Фамилия, собственное имя, отчество (необязательно), контактный номер телефона
									(обязательно для рассылки посредством SМS, Viber и Push), адрес электронной почты (обязательно для
									рассылки посредством электронной почты), ManID (уникальный идентификатор пользователя) (обязательно
									для рассылки посредством Push), номер бонусной карты (необязательно)
								</p>
							</td>
							<td>
								<p>Согласие (ст. 5, абзац 2 п. 1 ст. 9 Закона «О защите персональных данных»)</p>
							</td>
							<td>
								<p>
									До момента опубликования на интернет-сайтах и в аккаунтах ООО «Евроторг» в социальных сетях и
									мессенджерах в сети Интернет, в средствах массовой информации, с использованием средств наружной
									рекламы
								</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>13</p>
							</td>
							<td>
								<p>
									Направление информационной (рекламной, новостной и другой) рассылки посредством электронной почты и
									(или) SМS, Viber, Push
								</p>
							</td>
							<td>
								<p>
									Пользователи сайта, пользователи мобильного приложения, зарегистрированные в личном кабинете на сайте
									https://e-account.by, в том числе через сайты: https://evroopt.by, https://eplus.by,
									https://igra.evroopt.by, https://game.evroopt.by, https://igra.groshyk.by,
									https://bonsticks.evroopt.by или посредством мобильного приложения
								</p>
							</td>
							<td>
								<p>
									Фамилия, собственное имя, отчество (необязательно), контактный номер телефона (обязательно для
									рассылки посредством SМS, Viber и Push), адрес электронной почты (обязательно для рассылки посредством
									электронной почты), ManID (уникальный идентификатор пользователя) (обязательно для рассылки
									посредством Push), номер бонусной карты (необязательно)
								</p>
							</td>
							<td>
								<p>Согласие (ст. 5, абзац 2 п. 1 ст. 9 Закона «О защите персональных данных»)</p>
							</td>
							<td>
								<p>
									3 года с даты последней авторизации (входа) в личном кабинете или последнего использования бонусной
									карты, а в случае неиспользования бонусной карты и личного кабинета – 3 года с даты дачи согласия,
									либо до момента отказа (отписки) от получения информационной рассылки
								</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>14</p>
							</td>
							<td>
								<p>
									Демонстрация более подходящей по содержанию (релевантной) рекламной информации (об акциях, скидках и
									предложениях ООО «Евроторг» и привлеченных им партнеров) в глобальной компьютерной сети Интернет на
									посещаемых интернет-ресурсах (сайтах, в социальных сетях) и в мобильных приложениях
								</p>
							</td>
							<td>
								<p>
									Пользователи сайта, пользователи мобильных приложений, зарегистрированные в личном кабинете на сайте
									https://e-account.by, в том числе через сайты: https://evroopt.by, https://eplus.by,
									https://igra.evroopt.by, https://game.evroopt.by, https://igra.groshyk.by,
									https://bonsticks.evroopt.by или посредством мобильного приложения
								</p>
							</td>
							<td>
								<p>
									Контактный номер телефона, адрес электронной почты (необязательно), дата рождения (необязательно), пол
									(необязательно), количество членов семьи (необязательно), адрес места жительства (страна, область,
									район, населенный пункт, улица, дом) (необязательно)
								</p>
							</td>
							<td>
								<p>Согласие (ст. 5 Закона «О защите персональных данных»)</p>
							</td>
							<td>
								<p>
									3 года с даты последней авторизации (входа) в личном кабинете или последнего использования бонусной
									карты, а в случае неиспользования бонусной карты и личного кабинета – 3 года с даты дачи согласия,
									либо до момента отказа (отписки) от демонстрации релевантной рекламной информации
								</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>15</p>
							</td>
							<td>
								<p>
									Проведение маркетинговых и (или) рекламных мероприятий (розыгрышей товаров, конкурсов и других) в
									аккаунтах ООО «Евроторг» в социальных сетях и мессенджерах в сети Интернет, уведомление их участников
									о признании победителями (выигрыше), опубликование сведений о победителях указанных мероприятий в
									аккаунтах ООО «Евроторг» в социальных сетях и мессенджерах
								</p>
							</td>
							<td>
								<p>
									Пользователи социальных сетей и мессенджеров, принимающие участие в маркетинговых и (или) рекламных
									мероприятиях в аккаунтах ООО «Евроторг» в социальных сетях и мессенджерах в сети Интернет
								</p>
							</td>
							<td>
								<p>
									Название профиля (имя) пользователя (аккаунта), зарегистрированного в социальной сети, сведения,
									указанные в описании (шапке) профиля такого пользователя в социальной сети (необязательно), имя
									пользователя в мессенджере, номер мобильного телефона (необязательно), фотоизображение (аватар)
									профиля пользователя в мессенджере (необязательно), адрес электронной почты (обязательно для
									пользователей социальной сети YouTube), сведения о выигрыше
								</p>
							</td>
							<td>
								<p>
									Обработка в отношении распространенных ранее персональных данных до момента заявления субъектом
									персональных данных требований о прекращении обработки распространенных персональных данных, а также
									об их удалении при отсутствии иных оснований для обработки персональных данных (абзац 19 ст. 6 Закона
									«О защите персональных данных») – при обработке персональных данных участников маркетинговых и (или)
									рекламных мероприятий; Согласие (ст. 5, абзац 2 п. 1 ст. 9 Закона «О защите персональных данных») –
									при опубликовании сведений о победителях
								</p>
							</td>
							<td>
								<p>
									1 год со дня окончания маркетингового и (или) рекламного мероприятия в аккаунтах ООО «Евроторг» в
									социальных сетях и мессенджерах в сети Интернет
								</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>16</p>
							</td>
							<td>
								<p>
									Участие в качестве покупателя торговых объектов ООО «Евроторг» в съемке маркетинговых видеороликов в
									различных форматах (опросы, постановочные, развлекательные видео), размещение видеороликов в аккаунтах
									ООО «Евроторг» в социальных сетях и мессенджерах в сети Интернет
								</p>
							</td>
							<td>
								<p>
									Покупатели торговых объектов ООО «Евроторг», принимающие участие в съемке маркетинговых видеороликов в
									различных форматах (опросы, постановочные, развлекательные видео){' '}
								</p>
							</td>
							<td>
								<p>
									Фотоизображение, видеоизображение (видеозапись), сведения о покупках и иные сведения, озвученные
									субъектом персональных данных в ходе съемки (необязательно)
								</p>
							</td>
							<td>
								<p>Согласие (ст. 5, абзац 2 п. 1 ст. 9 Закона «О защите персональных данных»)</p>
							</td>
							<td>
								<p>
									До момента опубликования в аккаунтах ООО «Евроторг» в социальных сетях и мессенджерах в сети Интернет{' '}
								</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>17</p>
							</td>
							<td>
								<p>
									Участие в съемке видеоролика о рекламной игре «Удача в придачу!» или иных рекламных играх, о
									награждении победителей рекламных игр
								</p>
							</td>
							<td>
								<p>
									Члены семьи и близкие родственники победителей рекламной игры «Удача в придачу!» или иных рекламных
									игр
								</p>
							</td>
							<td>
								<p>
									Фамилия, собственное имя, отчество, населенный пункт (район, область) места жительства, род занятий
									(занимаемая должность (профессия), место учебы), возраст, фотоизображение, видеоизображение
									(видеозапись) участия в съемке видеоролика о рекламной игре, о награждении победителей рекламной игры
								</p>
							</td>
							<td>
								<p>Согласие (ст. 5, абзац 2 п. 1 ст. 9 Закона «О защите персональных данных»)</p>
							</td>
							<td>
								<p>
									До момента опубликования на интернет-сайтах и в аккаунтах ООО «Евроторг» в социальных сетях и
									мессенджерах в сети Интернет, в средствах массовой информации
								</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>18</p>
							</td>
							<td>
								<p>
									Использование персональных данных членов семьи и близких родственников победителей рекламной игры
									«Удача в придачу!» и иных рекламных игр в рекламных материалах ООО «Евроторг» (размещаемых, в том
									числе на интернет-сайтах и в аккаунтах ООО «Евроторг» в социальных сетях и мессенджерах в сети
									Интернет, в средствах массовой информации, с использованием средств наружной рекламы)
								</p>
							</td>
							<td>
								<p>
									Члены семьи и близкие родственники победителей рекламной игры «Удача в придачу!» или иных рекламных
									игр
								</p>
							</td>
							<td>
								<p>
									Фамилия, собственное имя, отчество, населенный пункт (район, область) места жительства, род занятий
									(занимаемая должность (профессия), место учебы), возраст, фотоизображение, видеоизображение
									(видеозапись) участия в съемке видеоролика о рекламной игре, о награждении победителей рекламной игры
								</p>
							</td>
							<td>
								<p>Согласие (ст. 5, абзац 2 п. 1 ст. 9 Закона «О защите персональных данных»)</p>
							</td>
							<td>
								<p>
									До момента опубликования на интернет-сайтах и в аккаунтах ООО «Евроторг» в социальных сетях и
									мессенджерах в сети Интернет, в средствах массовой информации, с использованием средств наружной
									рекламы
								</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>19</p>
							</td>
							<td>
								<p>
									Оказание клиентской поддержки и рассмотрение поступивших в виде электронного сообщения через форму
									«Обратная связь» на сайтах: https://e-account.by, https://evroopt.by, https://eplus.by,
									https://igra.evroopt.by, https://hitdiscount.by, https://groshyk.by, https://fermer.evroopt.by,
									https://invest.evroopt.by обращений (вопросов, отзывов) по качеству обслуживания, товаров и услуг, а
									также другим вопросам, интересуемым клиентов, направление ответов на такие обращения
								</p>
							</td>
							<td>
								<p>
									Пользователи сайта, пользователи мобильного приложения (покупатели, участники программ лояльности
									«Еплюс», представители юридических лиц и индивидуальные предприниматели (далее – ИП), иные физические
									лица)
								</p>
							</td>
							<td>
								<p>
									Фамилия (необязательно), собственное имя, отчество (необязательно), номер телефона, адрес электронной
									почты (необязательно, заполняется только при желании получить ответ по электронной почте), номер
									бонусной карты (необязательно), иные персональные данные, указанные в обращении
								</p>
							</td>
							<td>
								<p>Согласие (ст. 5, абзац 2 п. 1 ст. 9 Закона «О защите персональных данных»)</p>
							</td>
							<td>
								<p>3 года с даты дачи согласия</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>20</p>
							</td>
							<td>
								<p>
									Опубликование на Сайте ООО «Евроторг» в сети Интернет сведений о победителях рекламной игры (рекламных
									игр)
								</p>
							</td>
							<td>
								<p>
									Участники программ лояльности «Еплюс», зарегистрировавшие бонусную карту в личном кабинете на cайте
									https://e-account.by, в том числе через сайты: https://evroopt.by, https://eplus.by,
									https://igra.evroopt.by, https://game.evroopt.by, https://igra.groshyk.by,
									https://bonsticks.evroopt.by или посредством мобильного приложения, которые стали победителями
									рекламной игры (рекламных игр)
								</p>
							</td>
							<td>
								<p>Фамилия, инициалы, сведения о выигрыше, игровой код </p>
							</td>
							<td>
								<p>
									Обработка персональных данных является необходимой для выполнения обязанностей (полномочий),
									предусмотренных законодательными актами (абзац 20 ст. 6 Закона «О защите персональных данных», п. 11
									Положения о проведении рекламных игр на территории Республики Беларусь, утвержденного Указом
									Президента Республики Беларусь (далее – Президент) от 30.01.2003 № 51 «О проведении рекламных игр в
									Республике Беларусь»)
								</p>
							</td>
							<td>
								<p>10 лет со дня окончания рекламной игры</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>21</p>
							</td>
							<td>
								<p>Рассмотрение заявок юридических лиц и ИП на участие в партнерской программе «Еплюс»</p>
							</td>
							<td>
								<p>
									Представители юридических лиц и ИП, подавшие через сайт https://eplus.by заявку на участие в
									партнерской программе «Еплюс»
								</p>
							</td>
							<td>
								<p>
									Фамилия (необязательно), собственное имя, отчество (необязательно), контактный номер телефона, адрес
									электронной почты (необязательно, заполняется только при желании получить ответ по электронной почте)
								</p>
							</td>
							<td>
								<p>
									Обработка персональных данных осуществляется в процессе трудовой (служебной) деятельности субъекта
									персональных данных в случаях, предусмотренных законодательством (для юридических лиц), и на основании
									договора, заключаемого с субъектом персональных данных, в целях совершения действий, установленных
									этим договором (для ИП) (абзацы 8 и 15 ст. 6 Закона «О защите персональных данных», ст.ст. 7, 49 и п.
									5 ст. 186 Гражданского кодекса Республики Беларусь (далее – ГК))
								</p>
							</td>
							<td>
								<p>
									3 года после проведения налоговыми органами проверки соблюдения налогового законодательства. Если
									налоговыми органами проверка соблюдения налогового законодательства не проводилась – 10 лет после
									окончания срока действия договора
								</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>22</p>
							</td>
							<td>
								<p>Рассмотрение заявок юридических лиц (крестьянских и фермерских хозяйств) на сотрудничество</p>
							</td>
							<td>
								<p>
									Представители юридических лиц (крестьянских и фермерских хозяйств), подавшие через сайт
									https://fermer.evroopt.by заявку на сотрудничество
								</p>
							</td>
							<td>
								<p>Собственное имя и контактный номер телефона</p>
							</td>
							<td>
								<p>
									Обработка персональных данных осуществляется в процессе трудовой (служебной) деятельности субъекта
									персональных данных в случаях, предусмотренных законодательством (абзац 8 ст. 6 Закона «О защите
									персональных данных», ст.ст. 7, 49 и п. 5 ст. 186 ГК)
								</p>
							</td>
							<td>
								<p>
									3 года после проведения налоговыми органами проверки соблюдения налогового законодательства. Если
									налоговыми органами проверка соблюдения налогового законодательства не проводилась – 10 лет после
									окончания срока действия договора
								</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>23</p>
							</td>
							<td>
								<p>
									Рассмотрение заявок юридических лиц и ИП на сотрудничество в сфере внутриреспубликанских
									грузоперевозок{' '}
								</p>
							</td>
							<td>
								<p>
									Представители юридических лиц и ИП, подавшие через сайт https://evroopt.by заявку (карточку
									перевозчика) на сотрудничество в сфере внутриреспубликанских грузоперевозок
								</p>
							</td>
							<td>
								<p>
									Фамилия, собственное имя, отчество, контактный номер телефона, адрес электронной почты (необязательно,
									регистрационный знак автомобиля и полуприцепа/ прицепа (для ИП), сведения о наличии медицинской
									справки о состоянии здоровья
								</p>
							</td>
							<td>
								<p>
									Обработка персональных данных осуществляется в процессе трудовой (служебной) деятельности субъекта
									персональных данных в случаях, предусмотренных законодательством (для юридических лиц), и на основании
									договора, заключаемого с субъектом персональных данных, в целях совершения действий, установленных
									этим договором (для ИП) (абзацы 8 и 15 ст. 6 Закона «О защите персональных данных», ст.ст. 7, 49 и п.
									5 ст. 186 ГК)
								</p>
							</td>
							<td>
								<p>
									3 года после проведения налоговыми органами проверки соблюдения налогового законодательства. Если
									налоговыми органами проверка соблюдения налогового законодательства не проводилась – 10 лет после
									окончания срока действия договора
								</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>24</p>
							</td>
							<td>
								<p>
									Рассмотрение заявок юридических и физических лиц, в том числе ИП, на аренду вакантных площадей ООО
									«Евроторг»
								</p>
							</td>
							<td>
								<p>
									Физические лица (осуществляющие деятельность, направленную на получение прибыли, но не имеющие статуса
									ИП, а также ИП), представители юридических лиц, подавшие через сайты: https://ecity.evroopt.by,
									https://evroopt.by или посредством электронной почты заявку на аренду вакантных площадей ООО
									«Евроторг» (анкету арендатора)
								</p>
							</td>
							<td>
								<p>
									Фамилия, собственное имя, отчество, занимаемая должность, фактический и почтовый адрес (для
									физического лица), контактный номер телефона, адрес электронной почты, банковские реквизиты (для
									физического лица)
								</p>
							</td>
							<td>
								<p>
									Обработка персональных данных осуществляется в процессе трудовой (служебной) деятельности субъекта
									персональных данных в случаях, предусмотренных законодательством (для юридических лиц), и на основании
									договора, заключаемого с субъектом персональных данных, в целях совершения действий, установленных
									этим договором (для физических лиц) (абзацы 8 и 15 ст. 6 Закона «О защите персональных данных», ст.ст.
									7, 49 и п. 5 ст. 186 ГК)
								</p>
							</td>
							<td>
								<p>
									3 года после проведения налоговыми органами проверки соблюдения налогового законодательства. Если
									налоговыми органами проверка соблюдения налогового законодательства не проводилась – 10 лет после
									окончания срока действия договора
								</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>25</p>
							</td>
							<td>
								<p>
									Рассмотрение заявок физических лиц – арендаторов на проведение промо-мероприятий в торговых объектах
									ООО «Евроторг»
								</p>
							</td>
							<td>
								<p>
									Физические лица – арендаторы ООО Евроторг» (осуществляющие деятельность, направленную на получение
									прибыли, но не имеющие статуса ИП, а также ИП), подавшие через сайт https://ecity.evroopt.by или
									посредством электронной почты заявку на проведение промо-мероприятия в торговых объектах ООО
									«Евроторг»
								</p>
							</td>
							<td>
								<p>Фамилия, собственное имя, отчество, адрес электронной почты</p>
							</td>
							<td>
								<p>
									Обработка персональных данных осуществляется на основании договора, заключенного (заключаемого) с
									субъектом персональных данных, в целях совершения действий, установленных этим договором (абзац 15 ст.
									6 Закона «О защите персональных данных», ст. 7 ГК)
								</p>
							</td>
							<td>
								<p>
									3 года после проведения налоговыми органами проверки соблюдения налогового законодательства. Если
									налоговыми органами проверка соблюдения налогового законодательства не проводилась – 10 лет после
									окончания срока действия договора
								</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>26</p>
							</td>
							<td>
								<p>
									Рассмотрение заявок юридических и физических лиц, в том числе ИП, на размещение рекламной информации в
									ООО «Евроторг»
								</p>
							</td>
							<td>
								<p>
									Физические лица (осуществляющие деятельность, направленную на получение прибыли, но не имеющие статуса
									ИП, а также ИП), представители юридических лиц, подавшие через сайт https://evroopt.by или посредством
									электронной почты заявку на размещение рекламной информации в ООО «Евроторг»
								</p>
							</td>
							<td>
								<p>Фамилия, собственное имя, отчество, контактный номер телефона, адрес электронной почты</p>
							</td>
							<td>
								<p>
									Обработка персональных данных осуществляется в процессе трудовой (служебной) деятельности субъекта
									персональных данных в случаях, предусмотренных законодательством (для юридических лиц), и на основании
									договора, заключаемого с субъектом персональных данных, в целях совершения действий, установленных
									этим договором (для физических лиц) (абзацы 8 и 15 ст. 6 Закона «О защите персональных данных», ст.ст.
									7, 49 и п. 5 ст. 186 ГК)
								</p>
							</td>
							<td>
								<p>
									3 года после проведения налоговыми органами проверки соблюдения налогового законодательства. Если
									налоговыми органами проверка соблюдения налогового законодательства не проводилась – 10 лет после
									окончания срока действия договора
								</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>27</p>
							</td>
							<td>
								<p>Рассмотрение заявок юридических лиц и ИП на приобретение подарочных сертификатов ООО «Евроторг»</p>
							</td>
							<td>
								<p>
									Представители юридических лиц и ИП, подавшие через сайт https://sertifikat.evroopt.by заявку на
									приобретение подарочных сертификатов
								</p>
							</td>
							<td>
								<p>
									Фамилия, собственное имя, отчество, контактный номер телефона, адрес электронной почты, адрес места
									государственной регистрации (для ИП), банковские реквизиты (для ИП)
								</p>
							</td>
							<td>
								<p>
									Обработка персональных данных осуществляется в процессе трудовой (служебной) деятельности субъекта
									персональных данных в случаях, предусмотренных законодательством (для юридических лиц), и на основании
									договора, заключенного (заключаемого) с субъектом персональных данных, в целях совершения действий,
									установленных этим договором (для ИП) (абзацы 8 и 15 ст. 6 Закона «О защите персональных данных»,
									ст.ст. 7, 49 и п. 5 ст. 186 ГК)
								</p>
							</td>
							<td>
								<p>
									3 года после проведения налоговыми органами проверки соблюдения налогового законодательства. Если
									налоговыми органами проверка соблюдения налогового законодательства не проводилась – 10 лет после
									окончания срока действия договора
								</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>28</p>
							</td>
							<td>
								<p>Рассмотрение коммерческих предложений поставщиков (юридических лиц и ИП)</p>
							</td>
							<td>
								<p>
									Представители юридических лиц и ИП, подавшие через сайт https://evroopt.by коммерческое предложение о
									поставке товаров
								</p>
							</td>
							<td>
								<p>
									Собственное имя, контактный номер телефона (необязательно), адрес электронной почты, иные данные,
									указанные в коммерческом предложении
								</p>
							</td>
							<td>
								<p>
									Обработка персональных данных осуществляется в процессе трудовой (служебной) деятельности субъекта
									персональных данных в случаях, предусмотренных законодательством (для юридических лиц), и на основании
									договора, заключенного (заключаемого) с субъектом персональных данных, в целях совершения действий,
									установленных этим договором (для ИП) (абзацы 8 и 15 ст. 6 Закона «О защите персональных данных»,
									ст.ст. 7, 49 и п. 5 ст. 186 ГК)
								</p>
							</td>
							<td>
								<p>
									3 года после проведения налоговыми органами проверки соблюдения налогового законодательства. Если
									налоговыми органами проверка соблюдения налогового законодательства не проводилась – 10 лет после
									окончания срока действия договора
								</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>29</p>
							</td>
							<td>
								<p>Предварительная запись на личный прием </p>
							</td>
							<td>
								<p>Лица, обратившиеся на личный прием</p>
							</td>
							<td>
								<p>
									Фамилия, собственное имя, отчество, занимаемая должность (для работников ООО «Евроторг»), контактный
									номер телефона, суть вопроса
								</p>
							</td>
							<td>
								<p>
									Обработка персональных данных является необходимой для выполнения обязанностей (полномочий),
									предусмотренных законодательными актами (абзац 20 ст. 6 Закона «О защите персональных данных», ст. 6
									Закона от 18.07.2011 № 300-З «Об обращениях граждан и юридических лиц»)
								</p>
							</td>
							<td>
								<p>1 год со дня предварительной записи на личный прием</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>30</p>
							</td>
							<td>
								<p>Реализация прав потребителей в соответствии с законодательством о защите прав потребителей</p>
							</td>
							<td>
								<p>Покупатели (потребители, клиенты)</p>
							</td>
							<td>
								<p>
									Фамилия, собственное имя, отчество, адрес места жительства, контактный номер телефона, адрес
									электронной почты (необязательно), номер бонусной карты (необязательно)
								</p>
							</td>
							<td>
								<p>
									Обработка персональных данных является необходимой для выполнения обязанностей (полномочий),
									предусмотренных законодательными актами (абзац 20 ст. 6 Закона «О защите персональных данных», ст.ст.
									2, 5, 15 и 16 Закона от 09.01.2002 № 90-З «О защите прав потребителей»)
								</p>
							</td>
							<td>
								<p>
									3 года после проведения налоговыми органами проверки соблюдения налогового законодательства. Если
									налоговыми органами проверка соблюдения налогового законодательства не проводилась – 10 лет
								</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>31</p>
							</td>
							<td>
								<p>
									Рассмотрение кандидатов (анкет (резюме) соискателей) на вакантные должности в целях заключения
									трудового договора{' '}
								</p>
							</td>
							<td>
								<p>
									Физические лица (соискатели на трудоустройство), направившие анкеты (резюме), в том числе заполнившие
									онлайн-анкету или заявку на вакансию на сайте https://e-rabota.by, либо прошедшие онлайн-
									собеседование в онлайн-рекрутере «Подбор персонала «Евроопт» (recruit_euroopt_bot) в мессенджере
									Telegram, либо откликнувшиеся на размещенные вакансии на сайте https://e-rabota.by или сайтах для
									поиска работы (например, rabota.by), либо заполнившие анкету посредством звонка в контактный центр по
									тел. 7497 (единый номер для всех мобильных операторов) или тел. (017) 2798080, разместившие анкеты
									(резюме) на сайтах для поиска работы (например, rabota.by)
								</p>
							</td>
							<td>
								<p>
									Фотоизображение (необязательно), фамилия, собственное имя, отчество, дата рождения, гражданство, место
									рождения, семейное положение, адрес места жительства (регистрации), контактный номер телефона, адрес
									электронной почты, сведения об образовании, трудовой деятельности, воинском учете, наличии
									несовершеннолетних детей, привлечении к административной и уголовной ответственности и иные данные,
									указанные в анкете (резюме) соискателя; ID (уникальный идентификатор), имя пользователя и
									фотоизображение (аватар) профиля пользователя в мессенджере Telegram (для соискателей на
									трудоустройство, прошедших онлайн-собеседование в онлайн-рекрутере «Подбор персонала «Евроопт»
									(recruit_euroopt_bot) в мессенджере Telegram)
								</p>
							</td>
							<td>
								<p>
									Согласие (ст. 5, абзац 2 п. 1 ст. 9 Закона «О защите персональных данных») – при заполнении в ООО
									«Евроторг» анкеты (резюме) в письменной форме, при направлении (заполнении) анкеты (резюме),
									онлайн-анкеты или заявки на вакансию в электронном виде (на адрес электронной почты HR@eurotorg.by,
									через сайт https://e-rabota.by, онлайн-собеседование в онлайн-рекрутере «Подбор персонала «Евроопт»
									(recruit_euroopt_bot) в мессенджере Telegram, посредством звонка в контактный центр Оператора); Абзац
									16 ст. 6 Закона «О защите персональных данных» (обработка персональных данных, указанных в документе,
									адресованном оператору и подписанном субъектом персональных данных, в соответствии с содержанием
									такого документа) – при направлении (предоставлении) анкеты (резюме) в письменной форме или по
									электронной почте в виде электронного документа, подписанного электронной цифровой подписью; Абзац 19
									ст. 6 Закона «О защите персональных данных» (обработка распространенных ранее персональных данных)
									–при рассмотрении анкеты (резюме), размещенной на сайтах для поиска работы (например, rabota.by)
								</p>
							</td>
							<td>
								<p>
									1 год со дня дачи согласия, либо заполнения (поступления) анкеты (резюме). ID (уникальный
									идентификатор), имя пользователя и фотоизображение (аватар) профиля пользователя в мессенджере
									Telegram – не хранятся
								</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>32</p>
							</td>
							<td>
								<p>
									Ведение внешнего кадрового резерва кандидатов (соискателей) на вакансии в ООО «Евроторг» (для поиска и
									отбора кандидатов на замещение вакансий), информирование кандидатов о подходящих вакансиях
								</p>
							</td>
							<td>
								<p>
									Соискатели на трудоустройство, которым отказано в дальнейшем рассмотрении по текущей вакансии
									(вакансии, на которую откликнулся кандидат), либо которые сами отказались от рассмотрения предложенной
									им вакансии
								</p>
							</td>
							<td>
								<p>
									Фотоизображение (необязательно), фамилия, собственное имя, отчество, дата рождения, гражданство, место
									рождения, семейное положение, адрес места жительства (регистрации), контактный номер телефона, адрес
									электронной почты, сведения об образовании, трудовой деятельности, воинском учете, наличии
									несовершеннолетних детей, привлечении к административной и уголовной ответственности и иные данные,
									указанные в анкете (резюме) соискателя В соответствии со ст. 26 Трудового кодекса Республики Беларусь
									(далее – ТК) и иными законодательными актами
								</p>
							</td>
							<td>
								<p>Согласие (ст. 5, абзац 2 п. 1 ст. 9 Закона «О защите персональных данных»)</p>
							</td>
							<td>
								<p>3 года со дня дачи согласия </p>
							</td>
						</tr>
						<tr>
							<td>
								<p>33</p>
							</td>
							<td>
								<p>Оформление (прием) на работу</p>
							</td>
							<td>
								<p>Соискатели на трудоустройство, члены их семей</p>
							</td>
							<td>
								<p>
									В соответствии со ст. 26 Трудового кодекса Республики Беларусь (далее – ТК) и иными законодательными
									актами
								</p>
							</td>
							<td>
								<p>
									Обработка персональных данных осуществляется при оформлении трудовых (служебных) отношений, а также в
									процессе трудовой (служебной) деятельности субъекта персональных данных в случаях, предусмотренных
									законодательством (абзац 8 ст. 6, абзац 3 п. 2 ст. 8 Закона «О защите персональных данных», ст. 26 ТК,
									п. 11 Декрета Президента от 15.12.2014 № 5 «Об усилении требований к руководящим кадрам и работникам
									организаций»)
								</p>
							</td>
							<td>
								<p>55 лет после увольнения</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>34</p>
							</td>
							<td>
								<p>
									Рассмотрение обращений граждан и юридических лиц, в том числе внесенных в книгу замечаний и
									предложений, анализ таких обращений
								</p>
							</td>
							<td>
								<p>
									Физические лица, в том числе представители юридических лиц, направившие обращение, иные лица, чьи
									персональные данные указаны в обращении
								</p>
							</td>
							<td>
								<p>
									Фамилия, собственное имя, отчество (либо инициалы), адрес места жительства (места пребывания), суть
									обращения, личная подпись, иные сведения, указанные в обращении
								</p>
							</td>
							<td>
								<p>
									Обработка персональных данных является необходимой для выполнения обязанностей (полномочий),
									предусмотренных законодательными актами (абзац 20 ст. 6, абзац 17 п. 2 ст. 8 Закона «О защите
									персональных данных», ст. 12 Закона от 18.07.2011 № 300-З «Об обращениях граждан и юридических лиц»)
								</p>
							</td>
							<td>
								<p>
									5 лет с даты последнего обращения; 5 лет после окончания ведения книги замечаний и предложений и
									передачи ее в архив организации
								</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>35</p>
							</td>
							<td>
								<p>Рассмотрение заявлений субъектов персональных данных</p>
							</td>
							<td>
								<p>
									Физические лица – субъекты персональных данных, направившие заявление для реализации прав,
									предусмотренных статьями 10-13 Закона от 07.05.2021 № 99-З «О защите персональных данных», иные лица,
									чьи персональные данные указаны в заявлении
								</p>
							</td>
							<td>
								<p>
									Фамилия, собственное имя, отчество, адрес места жительства (места пребывания), дата рождения,
									идентификационный номер, номер документа, удостоверяющего личность субъекта персональных данных, суть
									заявления, личная подпись либо электронная цифровая подпись, иные сведения, указанные в заявлении
								</p>
							</td>
							<td>
								<p>
									Обработка персональных данных без согласия субъекта персональных данных прямо предусматривается
									Законом «О защите персональных данных» и иными законодательными актами (абзац 21 ст. 6, абзац 18 п. 2
									ст. 8 и ст. 14 названного Закона)
								</p>
							</td>
							<td>
								<p>1 год с даты поступления заявления </p>
							</td>
						</tr>
						<tr>
							<td>
								<p>36</p>
							</td>
							<td>
								<p>
									Учет согласий субъектов персональных данных на обработку их персональных данных после окончания срока,
									на который дается согласие
								</p>
							</td>
							<td>
								<p>
									Физические лица – субъекты персональных данных, давшие согласие на обработку их персональных данных
								</p>
							</td>
							<td>
								<p>Персональные данные, указанные в согласии субъектов персональных данных на их обработку</p>
							</td>
							<td>
								<p>
									Обработка персональных данных без согласия субъекта персональных данных прямо предусматривается
									законодательными актами (абзац 21 ст. 6, абзац 18 п. 2 ст. 8 Закона «О защите персональных данных»,
									Закон от 25.11.2011 № 323-З «Об архивном деле и делопроизводстве», постановление Министерства юстиции
									Республики Беларусь от 24.05.2012 № 140 «О перечне типовых документов»)
								</p>
							</td>
							<td>
								<p>1 год после окончания срока, на который дается согласие</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>37</p>
							</td>
							<td>
								<p>
									Заключение и исполнение гражданско-правовых договоров с субъектами хозяйствования (юридическими лицами
									и ИП), а также физическими лицами, учет и анализ договоров, контроль за исполнением договорных
									обязательств
								</p>
							</td>
							<td>
								<p>
									Работники Оператора и юридического лица –контрагента, уполномоченные на подписание договора и (или)
									совершение действий по его исполнению, ИП и иные физические лица – стороны гражданско-правового
									договора, иные лица, чьи персональные данные указаны в договоре
								</p>
							</td>
							<td>
								<p>
									Фамилия, собственное имя, отчество (либо инициалы) занимаемая должность, контактный номер телефона,
									адрес электронной почты (необязательно), адрес места государственной регистрации (для ИП), адрес места
									жительства (для физического лица), идентификационный номер (для физического лица), данные документа,
									удостоверяющего личность (серия, номер, дата выдачи, наименование органа, выдавшего документ) (для
									физического лица), страховой номер (для физического лица), банковские реквизиты (для ИП и физического
									лица), иные данные в соответствии с условиями договора (при необходимости)
								</p>
							</td>
							<td>
								<p>
									Обработка персональных данных осуществляется в процессе трудовой (служебной) деятельности субъекта
									персональных данных в случаях, предусмотренных законодательством (для юридических лиц), и на основании
									договора, заключенного (заключаемого) с субъектом персональных данных, в целях совершения действий,
									установленных этим договором (для ИП и физических лиц) (абзацы 8 и 15 ст. 6, абзац 3 п. 1 ст. 9 Закона
									«О защите персональных данных», ст.ст. 7, 49 и п. 5 ст. 186 ГК)
								</p>
							</td>
							<td>
								<p>
									3 года после проведения налоговыми органами проверки соблюдения налогового законодательства. Если
									налоговыми органами проверка соблюдения налогового законодательства не проводилась – 10 лет после
									окончания срока действия договора
								</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>38</p>
							</td>
							<td>
								<p>Предоставление безвозмездной (спонсорской) помощи физическим лицам</p>
							</td>
							<td>
								<p>
									Лица, которым оказывается безвозмездная (спонсорская) помощь, иные лица, чьи персональные данные
									указаны в договоре{' '}
								</p>
							</td>
							<td>
								<p>
									Фамилия, собственное имя, отчество, адрес места жительства, паспортные данные, контактный номер
									телефона лица, которому оказывается безвозмездная (спонсорская) помощь, иного лица, подписавшего
									договор
								</p>
							</td>
							<td>
								<p>
									Обработка персональных данных на основании договора, заключенного (заключаемого) с субъектом
									персональных данных, в целях совершения действий, установленных этим договором (абзац 15 ст. 6 Закона
									«О защите персональных данных»)
								</p>
							</td>
							<td>
								<p>
									3 года после проведения налоговыми органами проверки соблюдения налогового законодательства. Если
									налоговыми органами проверка соблюдения налогового законодательства не проводилась – 10 лет после
									окончания срока действия договора
								</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>39</p>
							</td>
							<td>
								<p>Определение круга и ведение учета аффилированных лиц ООО «Евроторг»</p>
							</td>
							<td>
								<p>Аффилированные лица ООО «Евроторг»</p>
							</td>
							<td>
								<p>
									Фамилия, собственное имя, отчество, сведения о степени родства и занимаемой должности, основания, в
									силу которых лицо признается аффилированным{' '}
								</p>
							</td>
							<td>
								<p>
									Обработка персональных данных является необходимой для выполнения обязанностей (полномочий),
									предусмотренных законодательными актами (абзац 20 ст. 6 Закона «О защите персональных данных», ст.ст.
									56 и 63 Закона от 09.12.1992 № 2020-XII «О хозяйственных обществах»)
								</p>
							</td>
							<td>
								<p>
									3 года после проведения налоговыми органами проверки соблюдения налогового законодательства. Если
									налоговыми органами проверка соблюдения налогового законодательства не проводилась – 10 лет после
									окончания срока действия договора
								</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>40</p>
							</td>
							<td>
								<p>
									Повышение контроля качества обслуживания клиентов контактного центра (в том числе разрешение возможных
									конфликтных ситуаций, управление качеством консультаций){' '}
								</p>
							</td>
							<td>
								<p>
									Покупатели, участники программ лояльности «Еплюс», участники и победители рекламных игр («Удача в
									придачу!» и др.), соискатели на трудоустройство, иные физические лица, обратившиеся на телефоны
									контактного центра (горячей линии)
								</p>
							</td>
							<td>
								<p>
									Аудиозапись телефонного разговора, номер телефона абонента, с которого осуществлен звонок, иные
									персональные данные, указанные в ходе телефонного разговора{' '}
								</p>
							</td>
							<td>
								<p>Согласие (ст. 5 Закона «О защите персональных данных»)</p>
							</td>
							<td>
								<p>6 месяцев со дня дачи согласия</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>41</p>
							</td>
							<td>
								<p>Обеспечение пропускного режима на объектах ООО «Евроторг», находящихся под охраной</p>
							</td>
							<td>
								<p>Посетители указанных объектов</p>
							</td>
							<td>
								<p>
									Фамилия, собственное имя (на русском языке и в латинской транскрипции, указанной в паспорте), отчество
								</p>
							</td>
							<td>
								<p>
									Обработка персональных данных является необходимой для выполнения обязанностей (полномочий),
									предусмотренных законодательными актами (абзац 20 ст. 6 Закона «О защите персональных данных», Закон
									от 08.11.2006 № 175-З «Об охранной деятельности», Указ Президента от 25.10.2007 № 534 «О мерах по
									совершенствованию охранной деятельности»)
								</p>
							</td>
							<td>
								<p>1 год со дня посещения объекта</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>42</p>
							</td>
							<td>
								<p>
									Обеспечение пропускного режима для транспортных средств на объектах ООО «Евроторг», находящихся под
									охраной
								</p>
							</td>
							<td>
								<p>Водители транспортных средств, въезжающие на охраняемую территорию указанных объектов</p>
							</td>
							<td>
								<p>
									Фамилия, собственное имя, отчество, контактный номер телефона, регистрационный знак транспортного
									средства (для физического лица, в том числе ИП, – собственника транспортного средства)
								</p>
							</td>
							<td>
								<p>
									Обработка персональных данных является необходимой для выполнения обязанностей (полномочий),
									предусмотренных законодательными актами (абзац 20 ст. 6 Закона «О защите персональных данных», Закон
									от 08.11.2006 № 175-З «Об охранной деятельности», Указ Президента от 25.10.2007 № 534 «О мерах по
									совершенствованию охранной деятельности»)
								</p>
							</td>
							<td>
								<p>1 год со дня посещения объекта</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>43</p>
							</td>
							<td>
								<p>
									Создание (регистрация) и использование личного кабинета на сайте https://ir.eurotorg.by/ru в разделе
									«Центр результатов» для получения доступа к размещенным в нем финансовым и операционным показателям
									работы ООО «Евроторг» и иной информации о компании (презентациям, пресс-релизам, финансовой
									отчетности)
								</p>
							</td>
							<td>
								<p>
									Физические лица – пользователи сайта https://ir.eurotorg.by/ru, в том числе ИП (инвесторы, аналитики,
									иные), зарегистрировавшие личный кабинет
								</p>
							</td>
							<td>
								<p>
									Собственное имя, фамилия (необязательно), страна местонахождения, адрес электронной почты, роль
									(инвестор, аналитик, другое), IP-адрес устройства, метаданные браузера, UUID (универсальный уникальный
									идентификатор)
								</p>
							</td>
							<td>
								<p>Согласие (ст. 5 Закона «О защите персональных данных»)</p>
							</td>
							<td>
								<p>
									3 года с даты последней авторизации (входа) в личном кабинете, а в случае неиспользования личного
									кабинета – 3 года с даты дачи согласия
								</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>44</p>
							</td>
							<td>
								<p>
									Размещение ООО «Евроторг» заказов поставщикам на сайте https://contractor-evroopt.by (о поставке
									товаров в торговые объекты)
								</p>
							</td>
							<td>
								<p>
									Представители юридических лиц и ИП (поставщики товаров), зарегистрированные на следующем сайте:
									https://contractor-evroopt.by
								</p>
							</td>
							<td>
								<p>
									Фамилия, собственное имя, отчество, контактный номер телефона, адрес электронной почты, адрес места
									государственной регистрации (для ИП), банковские реквизиты (для ИП)
								</p>
							</td>
							<td>
								<p>
									Обработка персональных данных осуществляется в процессе трудовой (служебной) деятельности субъекта
									персональных данных в случаях, предусмотренных законодательством (для юридических лиц), и на основании
									договора, заключенного (заключаемого) с субъектом персональных данных, в целях совершения действий,
									установленных этим договором (для ИП) (абзацы 8 и 15 ст. 6 Закона «О защите персональных данных»,
									ст.ст. 7, 49 и п. 5 ст. 186 ГК)
								</p>
							</td>
							<td>
								<p>
									3 года после проведения налоговыми органами проверки соблюдения налогового законодательства. Если
									налоговыми органами проверка соблюдения налогового законодательства не проводилась – 10 лет после
									окончания срока действия договора
								</p>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	);
};
